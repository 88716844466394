import React from 'react';
import styled from 'styled-components';
import { Container, Text14 } from '../../mixins/mixins';

const FooterComponent = styled.div`
  width: 100%;
  background: #1A1A1A;
  margin-top: auto;
`;

const FooterContainer = styled.div`
  ${Container};
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0.8rem 2.4rem;
    height: unset;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    margin-bottom: 3.6rem;
  }
`;

const Terms = styled.a`
  margin-right: 2.6rem;
  cursor: pointer;
  ${Text14};
  transition: 0.4s;
  &:hover {
  color: #0080FF;
}
  @media (max-width: 1024px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

const Privacy = styled(Terms)`
  margin-right: 0;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.8;
  .copyRight {
    ${Text14};
  }
  @media (max-width: 1024px) {
    .copyRight {
      font-size: 1.4rem;
    }
  }
`;

const Footer = () => (
  <FooterComponent>
    <FooterContainer>
      <Left>
        <Terms>Terms of use</Terms>
        <Privacy>Privacy Policy</Privacy>
      </Left>
      <Right>
        <p className="copyRight">© 2022 LAOBAN Ag. Zug, Switzerland.</p>
      </Right>
    </FooterContainer>
  </FooterComponent>
);

export default Footer;
