import React, {
  useState, useEffect, useContext, forwardRef, useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { colors, device } from '../../../variables/variables';
import {
  Text10, Text12, Text14, Text16, Text18,
} from '../../../mixins/mixins';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { WalletContext } from '../../../lib/wallet';
import { DefiSDK } from '../../../lib/defi-sdk';
import settingsIco from '../../../assets/img/settings.svg';

const SwapCurrencyWidget = styled.div`
  position: relative;
  width: 42.4rem;
  height: 42rem;
  margin-top: 2.4rem;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 2.2rem 2.4rem 2.4rem;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(2, 26, 50, 0.8);
    opacity: ${(props) => (props.openConfig ? '1' : 0)};
    pointer-events: none;
    transition: 1s opacity ease-in-out;
  }

  @media (max-width: 1024px) {
    height: unset;
    width: 100%;
    &:after {
      content: '';
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(2, 26, 50, 0.8);
      transform: none;
      transition: none;
    }

    :before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(240, 242, 242, 0.8);
      opacity: ${(props) => (props.openConfig ? 1 : 0)};
      z-index: 5;
      border-radius: 0.4rem;
      transition: 1s opacity ease-in-out;
      pointer-events: none;
    }

    transform: ${(props) => (props.openConfig ? 'translateY(-14.6rem)' : 'translateY(0)')};
    transition: 1s transform ease-in-out;
    overflow: visible;
    z-index: 3;
  }
`;

const SwapContainer = styled.div`
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 2.4rem;
    left: 2.4rem;
    right: 2.4rem;
    z-index: 100;
  }
`;

const SwapConfig = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobile} {
    margin-bottom: 2rem;
  }

  .nameWidget {
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: #35485B;
  }
  .settingsSwap {
    width: 2.4rem;
    height: 2.4rem;
  }
  .settingsSwap {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const SwapSettings = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Inputs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  .input {
    display: flex;
    color: black;
    border: 2px solid #A9B9CB;
  }
  .inputWrapper {
    position: relative;
  }
`;

const InputInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.4px;
  height: 2.4rem;
  color: #35485B;
`;

const SwitchCurrency = styled.div`
  display: flex;
  height: 4rem;
  justify-content: center;
  align-items: center;
  ${Text14};
  color: #35485B;
  border-radius: 0 0.4rem 0.4rem 0;
  background: none;
  position: absolute;
  right: 1.4rem;
  top: 3.2rem;
  @media ${device.mobile} {
    top: 2.4rem;
  }
  cursor: pointer;
`;

const TopCurrency = styled.input`
  position: relative;
  width: 100%;
  height: 5.6rem;
  background: white;
  border: 2px solid #A9B9CB;
  border-radius: 4px;
  margin-bottom: 2.4rem;
  padding-left: 1.6rem;
  ${Text16};
  @media (max-width: 1024px) {
    height: 4rem;
    width: 100%;
    border-left: 2px solid #A9B9CB;
    border-top: 2px solid #A9B9CB;
    border-bottom: 2px solid #A9B9CB;
  }
`;

const BottomCurrency = styled(TopCurrency)`
  margin-bottom: 1.6rem;
  `;

const SwapInfo = styled.div`
  background: #EEF1F5;
  border-radius: 4px;
  padding: 0.9rem 1.6rem 0.8rem;
  color: black;
  .wrapperRows {
    margin-bottom: 1.6rem;
    @media ${device.mobile} {
      padding: 0.9rem 1.6rem 0.8rem;
    }
  }
  @media ${device.mobile} {
    padding: 0;
  }
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  font-family: Inter, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  align-items: center;
  letter-spacing: 0.4px;
  color: #35485B;

  :last-child {
    margin-bottom: 0;
  }

  .nameTotal {
    ${Text12};
    font-weight: 400;
  }
`;

const Total = styled.div`
  ${Text12};
  font-weight: 400;
`;

const SwapButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  background: ${colors.bgBlue};
  border-radius: 0.4rem;
  margin: 1.6rem 0 0.4rem;
  ${Text18};
  color: white;
  cursor: pointer;

  :disabled {
    background: ${colors.bgLightBlue};
  }
  @media ${device.mobile} {
    height: 4rem;
    margin: 0.8rem 0 0.4rem;
  }
`;

const ConfigCurrency = styled.div`
  position: absolute;
  width: calc(100% - 1.6rem);
  height: 13.8rem;
  bottom: 0.8rem;
  left: 0.8rem;
  right: 0.8rem;
  z-index: ${(props) => (props.isMobile ? '0' : '2')};
  padding: 1rem 1.6rem 1.6rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  background: rgba(240, 242, 242, 1);
  transform: ${(props) => (props.openConfig ? 'translateX(0)' : 'translateX(calc(100% + 0.8rem))')};
  transition: 0.7s transform ease-in-out;

  .inputWrapper {
    width: 100%;
    display: flex;
    margin-top: 0.4rem;
    border: 2px solid #A9B9CB;
    border-radius: 0.4rem;
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
  }

  @media ${device.mobile} {
    transform: ${(props) => (props.openConfig ? 'translateY(15.4rem)' : 'translateY(31.2rem)')};
    transition: 1s transform ease-in-out;
    width: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    background: white;
  }
`;

const ConfigInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .configName {
    ${Text12};
    font-weight: 400;
    color: rgba(2, 26, 50, 0.8);
  }

  .configPercent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 1.6rem;
    background: rgba(240, 242, 242, 1);
    border-radius: 0.4rem;
    ${Text10};
    color: #28B550;
    background: rgba(240, 242, 242, 1);
  }
`;

const Switcher = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 50%;
  border-right: 2px solid #A9B9CB;;
  background: white;
  border-radius: 0.4rem 0 0 0.4rem;
`;

const SwitchItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.6rem;
  height: 3.6rem;
  border-radius: 0.4rem;
  background: ${(props) => (props.activeConfig ? '#095AAB' : 'none')};;
  ${Text16};
  color: ${(props) => (props.activeConfig ? 'white' : '#35485B')};
  @media ${device.mobile} {
    width: 50%;
  }
`;

const InputConfig = styled.input`
  width: 50%;
  height: 4rem;
  background: white;
  border-radius: 0 0.4rem 0.4rem 0;
  padding-left: 1.2rem;
  ${Text16};
  color: rgba(2, 26, 50, 0.2);
  :disabled {
    background: white;
    padding-left: 1.2rem;
  }
`;

const Cancel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.4rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  background: rgba(218, 230, 242, 1);
  ${Text14};
  color: #35485B;
  cursor: pointer;
`;

const Confirm = styled(Cancel)`
  background: rgba(9, 90, 171, 1);
  color: white;
`;

const initialSwapInfo = {
  inputToken: 'WBNB',
  inputValue: '',
  inputAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  outputToken: 'USDT',
  outputValue: '',
  outputAddress: '0x55d398326f99059ff775485246999027b3197955',
};

export const SwapWidget = forwardRef((props, ref) => {
  const { onSelect } = props;

  const isMobile = useIsMobile();
  const [openConfig, setOpenConfig] = useState(false);
  const [activeConfig, setActiveConfig] = useState('1');

  const wallet = useContext(WalletContext);
  const [balance, setBalance] = useState(0);
  const [estimate, setEstimation] = useState('$0');
  const [btnMessage, setBtnMessage] = useState('Swap');
  const [swapInfo, setSwapInfo] = useState(initialSwapInfo);
  // const [approveFirst, setApproveFirst] = useState(false);
  const [allocation, setAllocation] = useState([]);
  const [alreadySwapped, setAlreadySwapped] = useState(false);

  useEffect(() => {
    const main = async () => {
      const deposit = await new DefiSDK({ web3: wallet.web3 }).getUserTokenBalance({
        tokenAddress: swapInfo.inputAddress,
        owner: 'index',
      });
      setBalance(deposit);

      const data = await new DefiSDK({ web3: wallet.web3 }).getAllocation();
      setAllocation(data);

      if (!alreadySwapped) {
        if (data.length) {
          setSwapInfo({ ...swapInfo, inputToken: data[0].symbol, inputAddress: data[0].address });
          setAlreadySwapped(true);
        }
      }
    };

    if (wallet.isConnected) main();
  }, [wallet, wallet.isConnected, swapInfo.inputToken, swapInfo.inputAddress]);

  console.log({ allocation });

  useEffect(() => {
    const main = async () => {
      const owner = wallet.accounts[0];
      const defiSDK = new DefiSDK({ web3: wallet.web3 });
      // const isTokenApproved = await defiSDK.checkApproved({
      //   address: swapInfo.inputAddress, owner: 'index',
      // });
      // if (!isTokenApproved) {
      //   setApproveFirst(true);
      //   setBtnMessage(`Approve ${swapInfo.inputToken} tokens`);
      //   return;
      // }

      // setApproveFirst(false);
      setBtnMessage('Swap');

      const { value: outputValue, estimation } = await defiSDK.tradeAllocation({
        swapInfo,
        owner,
        estimation: true,
      });
      setSwapInfo(swapDetails => ({ ...swapDetails, outputValue }));
      setEstimation(estimation);
    };

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(+swapInfo.inputValue) && +swapInfo.inputValue > 0) main();
  // }, [swapInfo, wallet.accounts, wallet.web3, swapInfo.inputValue]);
  }, [wallet, swapInfo, swapInfo.inputValue]);

  const handleClick = async () => {
    const defiSDK = new DefiSDK({ web3: wallet.web3 });
    if (wallet.isConnected) {
      if (!swapInfo.inputValue) return;

      if (approveFirst) {
        await defiSDK.approve({ address: swapInfo.inputAddress, owner: wallet.accounts[0] });
      } else if (swapInfo.inputToken === 'WBNB') {
        await defiSDK.buyIndex({ swapInfo, owner: wallet.accounts[0] });
      } else {
        await defiSDK.sellIndex({ swapInfo, owner: wallet.accounts[0] });
      }
    }
  };

  const changeSwapInfo = inputSwapInfo => {
    console.log({ inputSwapInfo });
    setSwapInfo({
      ...initialSwapInfo,
      inputToken: inputSwapInfo.inputToken,
      inputAddress: inputSwapInfo.inputAddress,
    });
  };

  useImperativeHandle(ref, () => ({ changeSwapInfo }));

  return (
    <SwapContainer>
      <SwapCurrencyWidget openConfig={openConfig} isMobile={isMobile}>
        <SwapConfig>
          <span className="nameWidget">Swap</span>
          <SwapSettings onClick={() => setOpenConfig(true)}>
            <img src={settingsIco} alt="settings" />
          </SwapSettings>
        </SwapConfig>
        <Inputs>
          <div className="inputWrapper">
            <InputInfo>
              <div>You get</div>
              <div>{`Available: ${balance}`}</div>
            </InputInfo>
            <TopCurrency
              placeholder="0"
              value={swapInfo.inputValue}
              /* eslint-disable-next-line max-len */
              onChange={(e) => setSwapInfo(dataSwap => ({ ...dataSwap, inputValue: e.target.value }))}
              type="number"
              pattern="[0-9]{10}"
            />
            <SwitchCurrency
              onClick={() => onSelect({ modal: true, allocationData: allocation })}
            >
              {swapInfo.inputToken}
            </SwitchCurrency>
          </div>
          <div className="inputWrapper">
            <InputInfo>
              <div>You get</div>
              <div>Available: -</div>
            </InputInfo>
            <BottomCurrency
              placeholder="0"
              type="number"
              pattern="[0-9]{10}"
              value={swapInfo.outputValue}
              onChange={
                (e) => setSwapInfo(dataSwap => ({ ...dataSwap, outputValue: e.target.value }))
              }
            />
            <SwitchCurrency
              onClick={() => onSelect({ modal: true, allocationData: false })}
            >
              {swapInfo.outputToken}
            </SwitchCurrency>
          </div>
        </Inputs>
        {
          isMobile && (
            <SwapInfo>
              <div className="wrapperRows">
                <TotalRow>
                  <span className="nameTotal">Slippage</span>
                  <Total>0.5%</Total>
                </TotalRow>
                <TotalRow>
                  <span className="nameTotal">Network Free</span>
                  <Total>
                    ~
                    {estimate}
                  </Total>
                </TotalRow>
              </div>
              <SwapButton
                inputValue={swapInfo.inputValue}
                onClick={handleClick}
                disabled={swapInfo.inputValue.length <= 0 || swapInfo.outputValue <= 0}
              >
                {btnMessage}
              </SwapButton>
            </SwapInfo>
          )
        }
        {
          !isMobile && (
            <>
              <SwapInfo>
                <TotalRow>
                  <span className="nameTotal">Slippage</span>
                  <Total>0.5%</Total>
                </TotalRow>
                <TotalRow>
                  <span className="nameTotal">Network Free</span>
                  <Total>
                    ~
                    {estimate}
                  </Total>
                </TotalRow>
              </SwapInfo>
              <SwapButton
                inputValue={swapInfo.inputValue}
                onClick={handleClick}
                disabled={swapInfo.inputValue.length <= 0 || swapInfo.outputValue <= 0}
              >
                {btnMessage}
              </SwapButton>
            </>
          )
        }
        <ConfigCurrency openConfig={openConfig} isMobile={isMobile}>
          <ConfigInfo>
            <div className="configName">Slippage</div>
            <div className="configPercent">1.41%</div>
          </ConfigInfo>
          <div className="inputWrapper">
            <Switcher>
              <SwitchItem
                activeConfig={activeConfig === '1'}
                onClick={() => setActiveConfig('1')}
              >
                Auto
              </SwitchItem>
              <SwitchItem
                activeConfig={activeConfig === '2'}
                onClick={() => setActiveConfig('2')}
              >
                %
              </SwitchItem>
            </Switcher>
            <InputConfig
              placeholder="0"
              activeConfig={activeConfig}
              disabled={activeConfig === '1'}
            />
          </div>
          <div className="buttonWrapper">
            <Cancel onClick={() => setOpenConfig(false)}>Cancel</Cancel>
            <Confirm>Confirm</Confirm>
          </div>
        </ConfigCurrency>
      </SwapCurrencyWidget>
    </SwapContainer>
  );
});
