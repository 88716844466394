import React, { useState, useEffect, createContext } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import WalletConnectProvider from '@walletconnect/web3-provider';

export const WalletContext = createContext();

const Wallet = function ({ children }) {
  const [accounts, setAccounts] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [isLoadingMetamask, setIsLoadingMetamask] = useState(true);
  const [web3, setWeb3] = useState(new Web3(Web3.givenProvider));

  const connect = async function () {
    if (window) {
      const web3Modal = new Web3Modal({
        network: 'mainnet',
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: '334cc1ce78d74b838f96863e9af1108a',
            },
          },
        },
      });
      const provider = await web3Modal.connect();
      setWeb3(new Web3(provider));
    }
  };

  useEffect(() => {
    (async () => {
      if (window.ethereum !== undefined) {
        setIsMetamask(true);
        const getAccounts = await web3.eth.getAccounts();
        setAccounts(getAccounts);
        web3.currentProvider.on('accountsChanged', setAccounts);
        web3.currentProvider.on('disconnect', () => {
          setAccounts([]);
        });
      }
      setIsLoadingMetamask(false);
    })();
  }, [web3, web3.currentProvider, web3.eth]);

  useEffect(() => {
    if (accounts.length > 0) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [accounts]);

  const exp = {
    accounts,
    connect,
    isConnected,
    isMetamask,
    isLoadingMetamask,
    web3,
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <WalletContext.Provider value={exp}>
      {children}
    </WalletContext.Provider>
  );
};

export default Wallet;
