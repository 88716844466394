import React from 'react';
import styled from 'styled-components';
import { useIsMobile } from '../../hooks/useIsMobile';
import messageIco from '../../assets/img/letter_icon.svg';
import { colors, device } from '../../variables/variables';

const ContactUsComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: ${colors.bgLightBlue2};
  border-radius: 0.4rem;
  cursor: pointer;
  @media ${device.mobile} {
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    margin-right: 1.2rem
  }
`;

const ContactUs = ({ openPopupContact }) => {
  const isMobile = useIsMobile();
  return (
    <ContactUsComponent onClick={() => openPopupContact(true)}>
      {
        !isMobile
        && (
          <img src={messageIco} alt="message" />
        )
      }
      {
        isMobile
        && (
          <img src={messageIco} alt="message" />
        )
      }
    </ContactUsComponent>
  );
};
export default ContactUs;
