import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import MainComponent from './pages/main';
import { AdminComponent } from './pages/admin';

const App = () => {
  useEffect(() => {
    const observer = () => {
      const height = Math.max(
        window.innerHeight, document.documentElement.clientHeight,
      );
      const vh = `${height / 100}px`;
      document.body.style.setProperty('--vh', vh);
    };
    observer();
    window.addEventListener('resize', () => {
      observer();
    }, false);

    window.addEventListener('load', () => {
      observer();
    }, false);

    return () => {
      window.removeEventListener('resize', () => {
        observer();
      }, false);

      window.removeEventListener('load', () => {
        observer();
      }, false);
    };
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainComponent />} />
      </Routes>
      <Routes>
        <Route path="/admin" element={<AdminComponent />} />
      </Routes>
    </BrowserRouter>

  );
};

export default App;
