import { css } from 'styled-components';
import { device } from '../variables/variables';

export const Container = () => css`
  width: 114.4rem;
  padding: 0 2.4rem;
  margin: 0 auto;
  @media (max-width:1024px){
  width:100%;
  }
`;

export const Text10 = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.4px;
`;

export const Text12 = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const Text14 = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.4px;
`;

export const Text18Gilroy = () => css`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 3.2rem;
`;

export const Text16 = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 24px;
`;

export const Text18 = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const Text500 = () => css`
  font-family: 'Gilroy', sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
  font-style: normal;
  line-height: 4rem;
`;

export const Text12NormalMobile = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const Text16Normal = () => css`
  font-family: 'Gilroy', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  font-style: normal;
  line-height: 2.4rem;
  align-items: center;
  text-align: justify;
`;

export const Text18Normal = () => css`
  font-family: 'Gilroy', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  font-style: normal;
  line-height: 3.2rem;
`;

export const Text18NormalMobile = () => css`
  font-family: 'Gilroy' , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const Text18line32 = () => css`
  font-family: 'Gilroy' , sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const Text14NormalMobile = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const Text24Normal = () => css`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
`;

export const Text24s48Normal = () => css`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 48px;
`;

export const Text48Normal = () => css`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4.8rem;
  line-height: 6.7rem;
  letter-spacing: 0.02em;
`;

export const Text32NormalMobile = () => css`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 140%;
`;

export const Text32Normal = () => css`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 4.8rem;
  letter-spacing: 0.02em;
`;

export const Text16NormalMobile = () => css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const Text44Normal = () => css`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 4.4rem;
  line-height: 6.16rem;
  letter-spacing: 0.02em;
`;

export const Text72Normal = () => css`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 7.2rem;
  line-height: 140%;
  letter-spacing: 0.02em;
`;

export const Text80Normal = () => css`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8rem;
  line-height: 140%;
  letter-spacing: 0.02em;
`;

export const MainTable = () => css`
  width: 100%;
  transition: 0.2s opacity ease-in-out;
`;

export const HeaderTable = () => css`
  display: grid;
  height: 5rem;
  border-bottom: 1px solid #DDE4ED;
  font-weight: 500;
  ${Text14};
  color: #616A72;
  z-index: 4;
`;

export const RowTable = () => css`
  display: grid;
  height: 4.8rem;
  transition: 0.2s background-color ease-in-out;;
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: #1A1A1A;
  border-bottom: 1px solid #ccc;
  @media(max-width:1024px) {
    height: 19rem;
    :first-child {
      border-top: 1px solid #ccc;
    } 

    :last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
`;

export const BodyTableMain = () => css`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const TableCellMain = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media ${device.mobile} {
    height: 4.7rem;
    justify-content: center;
  }
`;
