/* eslint-disable */
// @todo sry im rushing
import axios from '../axios';
import { API_ENDPOINT } from '../../constants/axios'

export class Backend {
    constructor() {
        this.baseUrl = API_ENDPOINT
    }

    async getGeneral() {
        const res = await axios.request({ url: `${API_ENDPOINT}index/general`, method: 'get' })
        return res.data.result
    }

    async getAllocation() {
        const res = await axios.request({ url: `${API_ENDPOINT}index/allocation`, method: 'get' })
        return res.data.result
    }

    async getConfig() {
        const res = await axios.request({ url: `${API_ENDPOINT}index/config`, method: 'get' })
        return res.data.result[res.data.result.network]
    }

    async getGeneralHistory(period = 'day') {
        const res = await axios.request({ url: `${API_ENDPOINT}index/general/history?period=${period}`, method: 'get' })
        return res.data.result
    }

    async sendEmail(data) {
        const res = await axios.post(`${API_ENDPOINT}mail/form`, data)
        return res.data
    }
}
