export const exchangeIssuanceABI = [{ inputs: [{ internalType: 'address', name: '_weth', type: 'address' }, { internalType: 'address', name: '_uniFactory', type: 'address' }, { internalType: 'contract IUniswapV2Router02', name: '_uniRouter', type: 'address' }, { internalType: 'address', name: '_sushiFactory', type: 'address' }, { internalType: 'contract IUniswapV2Router02', name: '_sushiRouter', type: 'address' }, { internalType: 'contract IController', name: '_setController', type: 'address' }, { internalType: 'contract IBasicIssuanceModule', name: '_basicIssuanceModule', type: 'address' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_recipient', type: 'address',
  }, {
    indexed: true, internalType: 'contract ISetToken', name: '_setToken', type: 'address',
  }, {
    indexed: true, internalType: 'contract IERC20', name: '_inputToken', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: '_amountInputToken', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: '_amountSetIssued', type: 'uint256',
  }],
  name: 'ExchangeIssue',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_recipient', type: 'address',
  }, {
    indexed: true, internalType: 'contract ISetToken', name: '_setToken', type: 'address',
  }, {
    indexed: true, internalType: 'contract IERC20', name: '_outputToken', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: '_amountSetRedeemed', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: '_amountOutputToken', type: 'uint256',
  }],
  name: 'ExchangeRedeem',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_recipient', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: '_refundAmount', type: 'uint256',
  }],
  name: 'Refund',
  type: 'event',
}, {
  inputs: [], name: 'ETH_ADDRESS', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'WETH', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }], name: 'approveSetToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'contract IERC20', name: '_token', type: 'address' }], name: 'approveToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'contract IERC20[]', name: '_tokens', type: 'address[]' }], name: 'approveTokens', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'basicIssuanceModule', outputs: [{ internalType: 'contract IBasicIssuanceModule', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }, { internalType: 'contract IERC20', name: '_inputToken', type: 'address' }, { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' }], name: 'getAmountInToIssueExactSet', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }, { internalType: 'address', name: '_outputToken', type: 'address' }, { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' }], name: 'getAmountOutOnRedeemSet', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }, { internalType: 'contract IERC20', name: '_inputToken', type: 'address' }, { internalType: 'uint256', name: '_amountInput', type: 'uint256' }], name: 'getEstimatedIssueSetAmount', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }, { internalType: 'contract IERC20', name: '_inputToken', type: 'address' }, { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' }, { internalType: 'uint256', name: '_maxAmountInputToken', type: 'uint256' }], name: 'issue', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }, { internalType: 'contract IERC20', name: '_inputToken', type: 'address' }, { internalType: 'uint256', name: '_amountInput', type: 'uint256' }, { internalType: 'uint256', name: '_minSetReceive', type: 'uint256' }], name: 'issueSetForExactToken', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'contract ISetToken', name: '_setToken', type: 'address' }, { internalType: 'contract IERC20', name: '_outputToken', type: 'address' }, { internalType: 'uint256', name: '_amountSetToken', type: 'uint256' }, { internalType: 'uint256', name: '_minOutputReceive', type: 'uint256' }], name: 'redeem', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'setController', outputs: [{ internalType: 'contract IController', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'sushiFactory', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'sushiRouter', outputs: [{ internalType: 'contract IUniswapV2Router02', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'uniFactory', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'uniRouter', outputs: [{ internalType: 'contract IUniswapV2Router02', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}];
