import React from 'react';
import styled from 'styled-components';
import doc from '../../assets/img/doc.svg';
import {
  Text10, Text18Gilroy, Text24Normal,
} from '../../mixins/mixins';
import { device } from '../../variables/variables';
import { useIsMobile } from '../../hooks/useIsMobile';

const DataComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  color: black;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 2.7rem;
    padding-bottom: 3.2rem;
  }
`;

const DataContainer = styled.div`
  background: white;
  width: 100%;
  border-radius: 0.4rem;
  padding-top: 1rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  @media ${device.mobile} {
    padding-top: 0;
  }
`;

const Files = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.6rem;
  padding-bottom: 3.2rem;
  margin: 0 2.4rem;
  border-bottom: 2px solid #DDE4ED;
  @media (max-width: 1024px) {
    padding-bottom: 2.4rem;
    border-bottom: none;
    grid-gap: 0.8rem;
    margin-bottom: 0;
  }
`;

const UploadFile = styled.a`
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  background: white;
  cursor: pointer;
  border: 2px solid #fff;
  transition: 0.2s transform ease-in-out;
  text-decoration: none;
  

  @media (max-width: 1024px) {
    width: 9.8rem;
    height: 17.5rem;
    flex-direction: column;
    padding: 1.7rem 0 1.4rem;
    margin-right: 0;
    text-align: center;
    border: 1px solid #CCCCCC;
    &:hover {
      border: 1px solid #095AAB;
    }
  }
  
  .name {
    margin-bottom: 1.1rem;
    transition: 0.2s color ease-in-out;
    font-family: Inter, Arial, sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #1A1A1A;
    @media (max-width: 1024px) {
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin: 1.3rem 0 0.9rem;
    }
  }
  
  .property {
    ${Text10};
    color: rgba(36, 50, 64, 0.6);
    transition: 0.2s color ease-in-out;
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
  }
  
  &:hover {
    transform: translateY(-1rem);
    .name {
      color: #095AAB;
    }
    .property {
      color: #000000;
    }
  }
`;

const FileImage = styled.img`

`;

const FileInfo = styled.div`
  margin-left: 1.2rem;
  ${Text18Gilroy};
  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Title = styled.h1`
  ${Text24Normal};
  color: #1A1A1A;
  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.7);
  ${Text18Gilroy};
  color: #1A1A1A;

  :last-child {
    border-bottom: none;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
    border-bottom: none;
  }
`;

const RowName = styled.div`
  height: 5.6rem;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    height: 4.6rem;
  }
`;

const Volume = styled.div`

`;

const HeaderTitle = styled.div`
  @media ${device.mobile} {
    height: 3.2rem;
    margin-bottom: 0.7rem;
    ${Text24Normal};
    color: black;
    padding-left: 2.4rem;
  }
`;

const Data = () => {
  const isMobile = useIsMobile();
  return (
    <DataComponent>
      {
        isMobile && (
          <HeaderTitle>Data</HeaderTitle>
        )
      }
      <Files>
        <UploadFile href="#" download="">
          <FileImage src={doc} alt="ImgFile" />
          <FileInfo>
            <div className="name">Whitepaper</div>
            <div className="property">
              <p className="version">Version: 1.01</p>
              <p className="updateFile">Last update: 10.01.22</p>
            </div>
          </FileInfo>
        </UploadFile>
        <UploadFile href="#" download="">
          <FileImage src={doc} alt="ImgFile" />
          <FileInfo>
            <div className="name">Methodology</div>
            <div className="property">
              <p className="version">Version: 1.01</p>
              <p className="updateFile">Last update: 10.01.22</p>
            </div>
          </FileInfo>
        </UploadFile>
        <UploadFile href="#" download="">
          <FileImage src={doc} alt="ImgFile" />
          <FileInfo>
            <div className="name">Scoring</div>
            <div className="property">
              <p className="version">Version: 1.01</p>
              <p className="updateFile">Last update: 10.01.22</p>
            </div>
          </FileInfo>
        </UploadFile>
      </Files>
      <DataContainer>
        <Title>Characteristics</Title>
        <Row>
          <RowName>Number of Constituents</RowName>
          <Volume>10</Volume>
        </Row>
        <Row>
          <RowName>Constituents Market Cap</RowName>
          <Volume>2,913,283.92</Volume>
        </Row>
        <Row>
          <RowName>Max Market Cap</RowName>
          <Volume>2,913,283.92</Volume>
        </Row>
        <Row>
          <RowName>Min Market Cap</RowName>
          <Volume>2,913,283.92</Volume>
        </Row>
        <Row>
          <RowName>Avg Market Cap</RowName>
          <Volume>2,913,283.92</Volume>
        </Row>
      </DataContainer>
    </DataComponent>
  );
};
export default Data;
