export const scoreBoardItems = [
  {
    name: 'MBOX',
    tokenPrice: 6.1,
    priceChange: '18.56',
  },
  {
    name: 'BMB',
    tokenPrice: 3.5,
    priceChange: '-11.24',
  },
  {
    name: 'SCL',
    tokenPrice: 1.50,
    priceChange: '24',
  },
  {
    name: 'TCT',
    tokenPrice: 6.1,
    priceChange: '-17',
  },
  {
    name: 'XWG',
    tokenPrice: 0.16,
    priceChange: '18.56',
  },
  {
    name: 'MTN',
    tokenPrice: 2.16,
    priceChange: '2.6',
  },
  {
    name: 'CRB',
    tokenPrice: 3.5,
    priceChange: '9',
  },
  {
    name: 'CRB',
    tokenPrice: 3.5,
    priceChange: '9',
  },
];
