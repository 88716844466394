import React from 'react';
import styled from 'styled-components';
import TabsItem from './components/tabsItem';
import { Container } from '../../mixins/mixins';

const TabsComponent = styled.div`
  display: flex;
  align-items: center;
  color: #1A1A1A;
  border-bottom: 2px solid #DDE4ED;
`;

const TabsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5.6rem;
  ${Container};
`;

const Tabs = ({ activeTab, setActiveTab }) => {
  const changeTab = (currentTab) => {
    setActiveTab(currentTab);
  };
  return (
    <TabsComponent>
      <TabsContainer>
        <TabsItem nameTab="Overview" url="/" activeTab={activeTab} setActiveTab={setActiveTab} onClick={changeTab} />
        <TabsItem nameTab="Data" url="/data" activeTab={activeTab} setActiveTab={setActiveTab} onClick={changeTab} />
        <TabsItem nameTab="Constituents" url="/constituents" activeTab={activeTab} setActiveTab={setActiveTab} onClick={changeTab} />
      </TabsContainer>
    </TabsComponent>
  );
};

export default Tabs;
