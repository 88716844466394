import React from 'react';
import styled from 'styled-components';
import { device } from '../../variables/variables';
import logo from '../../assets/img/LogoLAO.svg';
import ConnectWallet from '../../components/ConnectWallet';
import ContactUs from '../../components/ContactUs';
import {
  Container, Text18Normal, Text24Normal,
} from '../../mixins/mixins';
import { useIsMobile } from '../../hooks/useIsMobile';

const HeaderComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7.2rem;
  background: white;
  color: black;
  @media ${device.mobile} {
    height: 4.8rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  ${Container};
  justify-content: space-between;
  align-items: center;
  @media ${device.mobile} {
    width: 100%;
    padding-left: 2.4rem;
    padding-right: 1.2rem;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
`;

const Logo = styled.img`
  @media ${device.mobile} {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const Title = styled.h1`
  ${Text24Normal};
  margin-left: 0.8rem;
  @media ${device.mobile} {
    ${Text18Normal};
    font-weight: 400;
  }
`;

const Header = ({ setOpenPopupContact }) => {
  const isMobile = useIsMobile();
  return (
    <HeaderComponent>
      <HeaderContainer>
        <Left>
          <Logo src={logo} />
          <Title>Indexes</Title>
        </Left>
        {
          isMobile
          && (
            <Right>
              <ContactUs openPopupContact={setOpenPopupContact} />
              <ConnectWallet />
            </Right>
          )
        }
        {
          !isMobile
          && (
            <Right>
              <ConnectWallet />
              <ContactUs openPopupContact={setOpenPopupContact} />
            </Right>
          )
        }
      </HeaderContainer>
    </HeaderComponent>
  );
};

export default Header;
