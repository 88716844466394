import { Line } from 'react-chartjs-2';
/* eslint-disable */
import Chart from 'chart.js/auto';
/* eslint-enable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text10, Text16, Text24Normal } from '../../mixins/mixins';
import { useIsMobile } from '../../hooks/useIsMobile';
import { device } from '../../variables/variables';
import { Backend } from '../../lib/defi-sdk/backend';

const OverViewComponent = styled.div`
  color: black;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const OverviewTitle = styled.div`
  font-size: 2.4rem;
`;

const OverviewTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    margin-bottom: 19px;
  }
`;

const OverviewCaption = styled.div`
  color: #1a1a1a;
  font-size: 1rem;
  opacity: 0.5;
`;

const Left = styled.div`
  padding: 1.8rem 2.4rem 1.4rem;
  .currencyWrapper {
    display: flex;
    margin-left: auto;
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
    padding: 0 2.4rem 2.8rem;
  }
`;

const OverViewBodyWrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const TimeCurrency = styled.div`
  display: flex;
  flex-direction: column;
  width: 17.6rem;

  .currency {
    ${Text24Normal};
    color: #28b550;
  }

  .namePeriod {
    ${Text16};
    color: rgba(26, 26, 26, 0.5);
  }

  @media (max-width: 1024px) {
    margin-right: 1.6rem;
    width: 10.4rem;
    .currency {
      font-size: 1.8rem;
    }

    .namePeriod {
      font-size: 1rem;
    }
  }
`;

const CurrencyPercent = styled(TimeCurrency)`
  width: 18rem;
  @media ${device.mobile} {
    width: unset;
  }
`;

const CurrencySum = styled(TimeCurrency)`
  position: relative;
  width: 18rem;

  //&:before {
  //  content: '';
  //  position: absolute;
  //  top: -1rem;
  //  left: 0;
  //  width: 4rem;
  //  height: 2px;
  //  background: #CCCCCC;
  //}

  @media (max-width: 1024px) {
    margin-top: 0;
    width: unset;
    &:before {
      display: none;
    }
  }
`;

const Comment = styled.p`
  ${Text10};
  color: rgba(26, 26, 26, 0.6);
  display: flex;
  height: 3rem;
  align-items: center;
  margin-left: 2.4rem;
`;

const Right = styled.div`
  width: 64.8rem;
  border-radius: 0.4rem;
  background: white;
  color: rgba(26, 26, 26, 0.6);
  font-size: 4.8rem;
  padding-bottom: 1.3rem;

  .graph {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 2.4rem;
    overflow: hidden;
  }
`;

const PeriodButton = styled.div`
  width: 55px;
  padding-top: 2px;
  height: 18px;
  font-size: 1.3rem;
  background-color: ${(props) => (props.active ? '#014d99;' : 'white;')}
  color: ${(props) => (props.active ? 'white;' : '#333333;')}
  border-radius: 0.4rem;
  text-align: center;
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;

  &: nth-child(1) {
    margin-left: 25px;
  }
`;

const CurrentPercentGreen = styled.div`
  color: rgba(40, 181, 80, 1);
`;

const CurrentPercentRed = styled.div`
  color: rgba(222, 27, 27, 1);
`;

/*
  color: ${(props) => (props.active ? 'white' : '#333333')};

*/

const updateDateLabelViaPeriod = (dateString, period = 'day') => {
  const d = new Date(dateString);

  if (period === 'day') {
    const hour = d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours();
    const minute = d.getMinutes().toString().length === 1
      ? `0${d.getMinutes()}`
      : d.getMinutes();

    return `${hour}:${minute}`;
  }

  if (period === 'month') {
    const day = d.getDate().toString().length === 1 ? `0${d.getDate()}` : d.getDate();
    const month = (d.getMonth() + 1).toString().length === 1
      ? `0${d.getMonth() + 1}`
      : d.getMonth() + 1;

    return `${day}/${month}`;
  }

  if (period === 'year') {
    const month = (d.getMonth() + 1).toString().length === 1
      ? `0${d.getMonth() + 1}`
      : d.getMonth() + 1;
    const year = d.getFullYear();

    return `${month}/${year}`;
  }

  return new Date(dateString).getDate();
};

const getDateYearAgo = () => {
  const d = new Date();
  d.setFullYear(d.getFullYear() - 1);
  return d;
};

const OverView = () => {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });
  const [APYStats, setAPYStats] = useState({
    priceYearAgo: 0,
    yearlyPriceChangePercent: 0,
    yearlyPriceChange: 0,
  });
  const [period, setPeriod] = useState('day');
  const isMobile = useIsMobile();
  const yearAgoDate = getDateYearAgo();

  const main = async () => {
    const backend = new Backend();

    const [rawHistory, allocation] = await Promise.all([
      backend.getGeneralHistory(period),
      backend.getAllocation(),
    ]);

    const currentPrice = allocation.reduce(
      (prev, curr) => prev + curr.allocationPrice,
      0,
    );
    const priceYearAgo = allocation.reduce(
      (prev, curr) => prev + parseFloat(curr.tokenPriceYearAgo) * curr.amount,
      0,
    );
    const yearlyPriceChangePercent = allocation.reduce(
      (prev, curr) => prev + curr.yearlyPriceChange,
      0,
    );

    setAPYStats({
      ...APYStats,
      priceYearAgo,
      yearlyPriceChangePercent,
      yearlyPriceChange:
        priceYearAgo > currentPrice
          ? currentPrice - priceYearAgo
          : priceYearAgo - currentPrice,
    });

    setGraphData({
      labels: rawHistory.map(({ time }) => updateDateLabelViaPeriod(time, period)),
      datasets: [
        {
          label: 'Total $',
          fill: 'start',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 280);
            gradient.addColorStop(0, 'rgba(84, 131, 185, 0.6)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
            return gradient;
          },
          borderColor: 'rgba(3, 78, 153, 1)',
          pointBorderWidth: 0,
          borderWidth: 3,
          data: rawHistory.map(({ price }) => price),
        },
      ],
    });
  };

  useEffect(() => {
    main();
  }, [period]);

  return (
    <OverViewComponent>
      <Left>
        {isMobile && (
          <OverviewTitleWrapper>
            <OverviewTitle>Overview</OverviewTitle>
            <OverviewCaption>*Based on historical data</OverviewCaption>
          </OverviewTitleWrapper>
        )}
        <OverViewBodyWrapper>
          <TimeCurrency>
            <p className="currency">
              {APYStats.priceYearAgo.toFixed(2)}
              {' '}
              BUSD
            </p>
            <span className="namePeriod">
              As of
              {' '}
              {yearAgoDate
                .toLocaleString('en-US', { month: 'short' })
                .toString()}
              {' '}
              {yearAgoDate.getDate()}
              ,
              {' '}
              {yearAgoDate.getFullYear()}
            </span>
          </TimeCurrency>
          <div className="currencyWrapper">
            <CurrencyPercent>
              <p className="currency">
                {APYStats.yearlyPriceChangePercent > 0 ? (
                  <CurrentPercentGreen>
                    {APYStats.yearlyPriceChangePercent.toFixed(2)}
                    %
                  </CurrentPercentGreen>
                ) : (
                  <CurrentPercentRed>
                    {APYStats.yearlyPriceChangePercent.toFixed(2)}
                    %
                  </CurrentPercentRed>
                )}
              </p>
              <span className="namePeriod">1 Year return %*</span>
            </CurrencyPercent>
            <CurrencySum>
              <p className="currency">
                {APYStats.yearlyPriceChange > 0 ? (
                  <CurrentPercentGreen>
                    {APYStats.yearlyPriceChange.toFixed(2)}
                    $
                  </CurrentPercentGreen>
                ) : (
                  <CurrentPercentRed>
                    {APYStats.yearlyPriceChange.toFixed(2)}
                    $
                  </CurrentPercentRed>
                )}
              </p>
              <span className="namePeriod">1 Year return $*</span>
            </CurrencySum>
          </div>
        </OverViewBodyWrapper>
      </Left>
      <Right>
        <PeriodButton
          active={period === 'day'}
          onClick={() => {
            setPeriod('day');
          }}
        >
          1 Day
        </PeriodButton>
        <PeriodButton
          active={period === 'month'}
          onClick={() => {
            setPeriod('month');
          }}
        >
          1 Month
        </PeriodButton>
        <PeriodButton
          active={period === 'year'}
          onClick={() => {
            setPeriod('year');
          }}
        >
          1 Year
        </PeriodButton>
        <Line
          data={graphData}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
            },
          }}
        />
        {!isMobile && <Comment>*Based on historical data</Comment>}
      </Right>
    </OverViewComponent>
  );
};

export default OverView;
