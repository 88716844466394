import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Text10, Text16, Text18, Text24Normal,
} from '../../mixins/mixins';
import close from '../../assets/img/close.svg';
import { colors, device } from '../../variables/variables';
import openDrop from '../../assets/img/open-up.svg';
import closeDropMenu from '../../assets/img/open-down.svg';
import submitCheck from '../../assets/img/sumbitCheck.svg';
import { Backend } from '../../lib/defi-sdk/backend';

const FormContactUSComponent = styled.div`
  position: absolute;
  top: 2.6rem;
  right: 2.4rem;
  width: 36rem;
  background: white;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  transform: ${(props) => (props.openPopupContact ? 'translateX(0)' : 'translateX(39rem)')};
  transition: 0.5s transform ease-in-out;
  @media ${device.mobile} {
    width: 31.2rem;
    top: 2.4rem;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const FormContainer = styled.form`
  width: 100%;
  padding: 1.1rem 2.4rem 2.4rem;
  .headerForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media ${device.mobile} {
  }
`;

const Title = styled.div`
  ${Text24Normal};
  color: black;
  margin-bottom: 1.5rem;
  @media ${device.mobile} {
    margin-bottom: 0;
  }
`;

const CloseForm = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  height: 4.8rem;
  background: #F0F2F2;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  margin-top: 0.4rem;
  ${Text16};
  font-weight: 400;
  color: #35485B;
  padding-left: 1.6rem;
  margin-bottom: 2.2rem;
  border: ${(props) => (props.error ? '1px solid red' : 'none')};
  :focus {
    outline: 1px solid #095AAB;
  }
  @media ${device.mobile} {
    height: 4rem;
    margin-bottom: 1rem;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 24rem;
  background: #F0F2F2;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  ${Text16};
  font-weight: 400;
  color: #35485B;
  padding-top: 1.6rem;
  padding-left: 1.6rem;
  margin-bottom: 3.2rem;
  resize: none;
  border: ${(props) => (props.error ? '1px solid red' : 'none')};
  :focus {
    border: 1px solid #095AAB;
  }
  @media ${device.mobile} {
    margin-bottom: 2.8rem;
    height: 20rem;
  }
`;

const Submit = styled.button`
  width: 100%;
  height: 4.8rem;
  background: ${colors.bgBlue};
  color: white;
  ${Text18};
  cursor: pointer;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  @media ${device.mobile} {
    height: 4rem;
  }
`;

const DropDown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  background: #F0F2F2;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  ${Text16};
  font-weight: 400;
  color: #35485B;
  padding-left: 1.6rem;
  margin-bottom: 3.2rem;
  cursor: pointer;
  outline: ${(props) => (props.openDropMenu ? '1px solid #095AAB' : 'none')};
  @media ${device.mobile} {
    margin-top: 1.8rem;
  }
`;

const SelectDropMenu = styled.div`
  width: 1.2rem;
  height: 0.7rem;
  position: absolute;
  top: 2.1rem;
  right: 1.6rem;
`;

const DropPaper = styled.div`
  display: ${(props) => (props.openDropMenu ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  background: #F0F2F2;
  position: absolute;
  top: 4.6rem;
  left: 0;
  z-index: 2;
  outline: 1px solid #095AAB;
  outline-offset: inherit;
`;

const PaperItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.8rem;
  padding-left: 1.6rem;
  ${Text16};
  color: #35485B;
  cursor: pointer;
`;

const Label = styled.label`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  color: rgba(53, 72, 91, 0.7);
  margin-left: 1.5rem;
  font-weight: 500;
  ${Text10};
`;

const SubmitMessage = styled.div`
  display: flex;
  height: 81.8rem;
  flex-direction: column;
  padding-top: 12rem;
  align-items: center;
  color: black;
  .submitChecked {
    
  }
  .messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20.8rem;
    @media ${device.mobile} {
      margin-top: 15rem;
    }
  }
  @media ${device.mobile} {
    padding-top: 6.4rem;
    height: 59.2rem;
  }
`;

const Message = styled.div`
  display: flex;
  margin-bottom: 1.3rem;
  justify-content: center;
  text-align: center;
  ${Text24Normal};  
  color: black;
`;

const FormContactUS = ({ setOpenPopupContact, openPopupContact }) => {
  const backend = new Backend();
  const [openDropMenu, setOpenDropMenu] = useState(false);
  const [targetItem, setTargetItem] = useState('Request Type');
  const [feedSubmit, setFeedSubmit] = useState(false);
  const FeedBackSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    company: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    requestDetail: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });
  return (
    <FormContactUSComponent openPopupContact={openPopupContact}>
      {
        !feedSubmit
        && (
          <Formik
            initialValues={{
              firstName: '', lastName: '', company: '', email: '', requestDetail: '',
            }}
            validationSchema={FeedBackSchema}
            onSubmit={(values) => {
              backend.sendEmail(values).then(
                () => {
                  setFeedSubmit(true);
                },
              );
            }}
          >
            {
             ({
               touched,
               errors,
               values,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
               <FormContainer onSubmit={handleSubmit}>
                 <div className="headerForm">
                   <Title>Send an offer</Title>
                   <CloseForm onClick={() => setOpenPopupContact(false)}>
                     <img src={close} alt="Close" />
                   </CloseForm>
                 </div>
                 <Label visible={values.firstName.length}>First name</Label>
                 <Input
                   name="firstName"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.firstName}
                   error={errors.firstName && touched.firstName}
                   placeholder="First Name"
                 />
                 <Label visible={values.lastName.length}>Last name</Label>
                 <Input
                   name="lastName"
                   onChange={handleChange}
                   value={values.lastName}
                   onBlur={handleBlur}
                   error={errors.lastName && touched.lastName}
                   placeholder="Last Name"
                 />
                 <Label visible={values.company.length}>Company</Label>
                 <Input
                   name="company"
                   onChange={handleChange}
                   value={values.company}
                   onBlur={handleBlur}
                   error={errors.company && touched.company}
                   placeholder="Company"
                 />
                 <Label visible={values.email.length}>Email</Label>
                 <Input
                   name="email"
                   onChange={handleChange}
                   value={values.email}
                   onBlur={handleBlur}
                   error={errors.email && touched.email}
                   placeholder="Email"
                 />
                 <DropDown
                   onClick={() => setOpenDropMenu(!openDropMenu)}
                   openDropMenu={openDropMenu}
                   errorType={targetItem === 'Request Type'}
                 >
                   <SelectDropMenu>
                     <img src={openDropMenu ? openDrop : closeDropMenu} alt="DropMenu" />
                   </SelectDropMenu>
                   {targetItem}
                   <DropPaper openDropMenu={openDropMenu}>
                     <PaperItem
                       onClick={() => setTargetItem('Contact an Index Salesperson')}
                     >
                       Contact an Index Salesperson
                     </PaperItem>
                     <PaperItem
                       onClick={() => setTargetItem('Index Data Inquiry')}
                     >
                       Index Data Inquiry
                     </PaperItem>
                     <PaperItem
                       onClick={() => setTargetItem('Index Information Inquiry')}
                     >
                       Index Information Inquiry
                     </PaperItem>
                   </DropPaper>
                 </DropDown>
                 <TextArea
                   name="requestDetail"
                   onChange={handleChange}
                   value={values.requestDetail}
                   onBlur={handleBlur}
                   error={errors.requestDetail && touched.requestDetail}
                   placeholder="Request Details"
                 />
                 <Submit
                   type="submit"
                 >
                   Submit
                 </Submit>
               </FormContainer>
             )
          }
          </Formik>
        )
      }
      {
        feedSubmit
        && (
          <SubmitMessage>
            <CloseForm onClick={() => {
              setOpenPopupContact(false);
              setFeedSubmit(false);
            }}
            >
              <img src={close} alt="Close" />
            </CloseForm>
            <div className="messageWrapper">
              <Message>Your message was sent successfully</Message>
              <img src={submitCheck} alt="Success" className="submitChecked" />
            </div>
          </SubmitMessage>
        )
      }
    </FormContactUSComponent>
  );
};

export default FormContactUS;
