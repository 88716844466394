import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { DefiSDK } from '../../../lib/defi-sdk';
import { WalletContext } from '../../../lib/wallet';

const initialTokens = [
  {
    name: 'Tether', symbol: 'USDT', address: '0x55d398326f99059ff775485246999027b3197955', addressView: '0x55d3...7955', amount: 0,
  },
  {
    name: 'Ethereum', symbol: 'WETH', address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8', addressView: '0x2170...33f8', amount: 0,
  },
  {
    name: 'Wrapped BNB', symbol: 'WBNB', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', addressView: '0xbb4C...095c', amount: 0,
  },
];

const Main = styled.div`
  position: absolute;
  left: 50%;
`;

const Wrapper = styled.div`
  position: relative;
  left: -50%;
  height: 500px;
  width: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: rgb(40, 13, 95);
  padding: 10px 20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Label = styled.div`
  font-size: 26px;
`;

const Search = styled.div`
  padding: 20px 0;
  width: 100%;
  color: rgb(40, 13, 95);
`;

const SearchInput = styled.input`
  height: 40px;
  width: 100%;
  color: rgb(40, 13, 95);
  border: 2px solid #ccc;
  font-size: 16px;
  padding: 0 10px;
`;

const TokenList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CurrencyModal = ({ allocation, onSelect, onInputSwapSelect }) => {
  const wallet = useContext(WalletContext);
  const [tokens, setTokens] = useState(allocation.length ? allocation : initialTokens);

  const searchToken = async (address) => {
    if (address.length === 0) {
      setTokens(initialTokens);
    } else if (address.length !== 42) {
      setTokens([{
        name: '', symbol: '', amount: 0, address: 'invalid address',
      }]);
    } else {
      const token = await new DefiSDK({ web3: wallet.web3 }).getTokenInfo(address);
      setTokens([token]);
    }
  };

  const handleTokenSelection = (data) => {
    onInputSwapSelect({ address: data.address, symbol: data.symbol });
    console.log(data);
    onSelect(false);
  };

  return (
    <Main>
      <Wrapper>
        <Top>
          <Label>Select a token</Label>
          <div style={{ cursor: 'pointer' }} onClick={() => onSelect({ modal: false, allocationData: false })}>
            <svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
            </svg>
          </div>
        </Top>
        <Search>
          <SearchInput onChange={(e) => searchToken(e.target.value)} placeholder="0x4124..." />
        </Search>
        <TokenList>
          {tokens.slice(0, 6).map((el, index) => (
            <div
              onClick={() => handleTokenSelection(el)}
              key={index}
              style={{
                cursor: 'pointer',
                padding: '10px 20px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '16px' }}>{el.symbol}</div>
                <div style={{ fontSize: '12px' }}>{el.name}</div>
              </div>
              <div style={{ fontSize: '16px', alignSelf: 'center' }}>
                {el.addressView}
              </div>
              <div style={{ fontSize: '20px', alignSelf: 'center' }}>
                {el.amount}
              </div>
            </div>
          ))}
        </TokenList>
      </Wrapper>
    </Main>
  );
};
