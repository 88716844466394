/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import swapArrow from '../../assets/img/SwapArrow.svg';
import { colors, device } from '../../variables/variables';
import {
  Text10, Text12, Text14, Text16, Text18,
} from '../../mixins/mixins';
import { useIsMobile } from '../../hooks/useIsMobile';
import { WalletContext } from '../../lib/wallet';
import { DefiSDK } from '../../lib/defi-sdk';
import { Backend } from '../../lib/defi-sdk/backend';
import settingsIco from '../../assets/img/settings.svg';

const SwapCurrencyWidget = styled.div`
  position: relative;
  width: 42.4rem;
  height: 42rem;
  margin-top: 2.4rem;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 2.2rem 2.4rem 2.4rem;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(2, 26, 50, 0.8);
    opacity: ${(props) => (props.openConfig ? '1' : 0)};
    pointer-events: none;
    transition: 1s opacity ease-in-out;
  }

  @media (max-width: 1024px) {
    height: unset;
    width: 100%;
    &:after {
      content: '';
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(2, 26, 50, 0.8);
      transform: none;
      transition: none;
    }

    :before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(240, 242, 242, 0.8);
      opacity: ${(props) => (props.openConfig ? 1 : 0)};
      z-index: 5;
      border-radius: 0.4rem;
      transition: 1s opacity ease-in-out;
      pointer-events: none;
    }

    transform: ${(props) => (props.openConfig ? 'translateY(-14.6rem)' : 'translateY(0)')};
    transition: 1s transform ease-in-out;
    overflow: visible;
    z-index: 3;
  }
`;

const SwapContainer = styled.div`
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 2.4rem;
    left: 2.4rem;
    right: 2.4rem;
    z-index: 100;
  }
`;

const SwapConfig = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobile} {
    margin-bottom: 2rem;
  }

  .nameWidget {
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: #35485B;
  }
  .settingsSwap {
    width: 2.4rem;
    height: 2.4rem;
  }
  .settingsSwap {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const SwapSettings = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Inputs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  .input {
    display: flex;
    color: black;
    border: 2px solid #A9B9CB;
  }
  .inputWrapper {
    position: relative;
  }
`;

const InputInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.4px;
  height: 2.4rem;
  color: #35485B;
`;

const SwitchCurrency = styled.div`
  display: flex;
  height: 4rem;
  justify-content: center;
  align-items: center;
  ${Text14};
  color: #35485B;
  border-radius: 0 0.4rem 0.4rem 0;
  background: none;
  position: absolute;
  right: 1.4rem;
  top: 3.2rem;
  @media ${device.mobile} {
    top: 2.4rem;
  }
`;

const TopCurrency = styled.input`
  position: relative;
  width: 100%;
  height: 5.6rem;
  background: white;
  border: 2px solid #A9B9CB;
  border-radius: 4px;
  margin-bottom: 2.4rem;
  padding-left: 1.6rem;
  ${Text16};
  @media (max-width: 1024px) {
    height: 4rem;
    width: 100%;
    border-left: 2px solid #A9B9CB;
    border-top: 2px solid #A9B9CB;
    border-bottom: 2px solid #A9B9CB;
  }
`;

const BottomCurrency = styled(TopCurrency)`
  margin-bottom: 1.6rem;
  `;

const SwitchWrapper = styled.div`
  position: absolute;
  top: 9.2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  @media ${device.mobile} {
    top: 7.5rem;
  }
`;

const SwitchButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  background: #DAE6F2;
  cursor: pointer;
`;

const SwapInfo = styled.div`
  background: #EEF1F5;
  border-radius: 4px;
  padding: 0.9rem 1.6rem 0.8rem;
  color: black;
  .wrapperRows {
    margin-bottom: 1.6rem;
    @media ${device.mobile} {
      padding: 0.9rem 1.6rem 0.8rem;
    }
  }
  @media ${device.mobile} {
    padding: 0;
  }
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  font-family: Inter, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  align-items: center;
  letter-spacing: 0.4px;
  color: #35485B;

  :last-child {
    margin-bottom: 0;
  }

  .nameTotal {
    ${Text12};
    font-weight: 400;
  }
`;

const Total = styled.div`
  ${Text12};
  font-weight: 400;
`;

const SwapButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 4.8rem;
  background: ${colors.bgBlue};
  border-radius: 0.4rem;
  margin: 1.6rem 0 0.4rem;
  ${Text18};
  color: white;
  cursor: pointer;

  :disabled {
    background: ${colors.bgLightBlue};
  }
  @media ${device.mobile} {
    height: 4rem;
    margin: 0.8rem 0 0.4rem;
  }
`;

const ConfigCurrency = styled.div`
  position: absolute;
  width: calc(100% - 1.6rem);
  height: 13.8rem;
  bottom: 0.8rem;
  left: 0.8rem;
  right: 0.8rem;
  z-index: ${(props) => (props.isMobile ? '0' : '2')};
  padding: 1rem 1.6rem 1.6rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  background: rgba(240, 242, 242, 1);
  transform: ${(props) => (props.openConfig ? 'translateX(0)' : 'translateX(calc(100% + 0.8rem))')};
  transition: 0.7s transform ease-in-out;

  .inputWrapper {
    width: 100%;
    display: flex;
    margin-top: 0.4rem;
    border: 2px solid #A9B9CB;
    border-radius: 0.4rem;
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
  }

  @media ${device.mobile} {
    transform: ${(props) => (props.openConfig ? 'translateY(15.4rem)' : 'translateY(31.2rem)')};
    transition: 1s transform ease-in-out;
    width: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    background: white;
  }
`;

const ConfigInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .configName {
    ${Text12};
    font-weight: 400;
    color: rgba(2, 26, 50, 0.8);
  }

  .configPercent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 1.6rem;
    background: rgba(240, 242, 242, 1);
    border-radius: 0.4rem;
    ${Text10};
    color: #28B550;
    background: rgba(240, 242, 242, 1);
  }
`;

const Switcher = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 50%;
  border-right: 2px solid #A9B9CB;;
  background: white;
  border-radius: 0.4rem 0 0 0.4rem;
`;

const SwitchItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.6rem;
  height: 3.6rem;
  border-radius: 0.4rem;
  background: ${(props) => (props.activeConfig ? '#095AAB' : 'none')};;
  ${Text16};
  color: ${(props) => (props.activeConfig ? 'white' : '#35485B')};
  @media ${device.mobile} {
    width: 50%;
  }
`;

const InputConfig = styled.input`
  width: 50%;
  height: 4rem;
  background: white;
  border-radius: 0 0.4rem 0.4rem 0;
  padding-left: 1.2rem;
  ${Text16};
  color: rgba(2, 26, 50, 0.2);
  :disabled {
    background: white;
    padding-left: 1.2rem;
  }
`;

const Cancel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.4rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  background: rgba(218, 230, 242, 1);
  ${Text14};
  color: #35485B;
  cursor: pointer;
`;

const Confirm = styled(Cancel)`
  background: rgba(9, 90, 171, 1);
  color: white;
`;

const inputToken = 'WETH';
const outputToken = 'IGB10';

const SwapWidget = () => {
  const isMobile = useIsMobile();
  const [openConfig, setOpenConfig] = useState(false);
  const [activeConfig, setActiveConfig] = useState('1');

  const wallet = useContext(WalletContext);
  const [balance, setBalance] = useState(0);
  const [estimate, setEstimation] = useState('$0');
  const [btnMessage, setBtnMessage] = useState('Swap');
  const [swapInfo, setSwapInfo] = useState(null);
  const [approveFirst, setApproveFirst] = useState(true);
  const [defiSDK, setDefiSDK] = useState(null);

  useEffect(() => {
    const main = async () => {
      const config = await new Backend().getConfig();

      if (!swapInfo) {
        setSwapInfo({
          inputToken,
          inputValue: '',
          inputAddress: config.external.WETH,
          outputToken,
          outputValue: '',
          outputAddress: config.internal.TokenSet,
        });
      }

      if (!defiSDK) {
        const sdk = new DefiSDK({ web3: wallet.web3, config });
        setDefiSDK(sdk);
      }

      if (defiSDK && swapInfo) {
        const deposit = await defiSDK.getUserTokenBalance({
          tokenAddress: swapInfo.inputAddress,
          owner: wallet.accounts[0],
        });
        setBalance(deposit);
      }
    };

    if (wallet.isConnected) main();
  }, [wallet, wallet.isConnected, swapInfo, defiSDK]);

  useEffect(() => {
    const main = async () => {
      const owner = wallet.accounts[0];
      const isTokenApproved = await defiSDK.checkApproved({
        address: swapInfo.inputAddress, owner,
      });
      if (!isTokenApproved) {
        setApproveFirst(true);
        setBtnMessage(`Approve ${swapInfo.inputToken} tokens`);
        return;
      }

      setApproveFirst(false);
      setBtnMessage('Swap');

      if (swapInfo.inputToken === inputToken) {
        const { value: outputValue, estimation } = await defiSDK.getEthSwap({ swapInfo, owner });
        setSwapInfo(swapDetails => ({ ...swapDetails, outputValue }));
        setEstimation(estimation);
      } else {
        const { value: outputValue, estimation } = await defiSDK.getTokenSwap({ swapInfo, owner });
        setSwapInfo(swapDetails => ({ ...swapDetails, outputValue }));
        setEstimation(estimation);
      }
    };

    if (defiSDK && swapInfo && !isNaN(+swapInfo.inputValue) && +swapInfo.inputValue > 0) main();
  }, [wallet, defiSDK, swapInfo]);

  const handleClick = async () => {
    if (wallet.isConnected) {
      if (!swapInfo.inputValue) return;

      if (approveFirst) {
        await defiSDK.approve({ address: swapInfo.inputAddress, owner: wallet.accounts[0] });
      } else if (swapInfo.inputToken === inputToken) {
        await defiSDK.buyIndex({ swapInfo, owner: wallet.accounts[0] });
      } else {
        await defiSDK.sellIndex({ swapInfo, owner: wallet.accounts[0] });
      }
    }
  };

  return (
    <SwapContainer>
      <SwapCurrencyWidget openConfig={openConfig} isMobile={isMobile}>
        <SwapConfig>
          <span className="nameWidget">Swap</span>
          <SwapSettings onClick={() => setOpenConfig(true)}>
            <img src={settingsIco} alt="settings" />
          </SwapSettings>
        </SwapConfig>
        <Inputs>
          <div className="inputWrapper">
            <InputInfo>
              <div>You get</div>
              <div>{`Available: ${balance}`}</div>
            </InputInfo>
            <TopCurrency
              placeholder="0"
              value={swapInfo ? swapInfo.inputValue : 0}
              /* eslint-disable-next-line max-len */
              onChange={(e) => setSwapInfo(dataSwap => ({ ...dataSwap, inputValue: e.target.value }))}
              type="number"
              pattern="[0-9]{10}"
            />
            <SwitchCurrency>{swapInfo ? swapInfo.inputToken : inputToken}</SwitchCurrency>
          </div>
          <div className="inputWrapper">
            <InputInfo>
              <div>You get</div>
              <div>Available: -</div>
            </InputInfo>
            <BottomCurrency
              placeholder="0"
              type="number"
              pattern="[0-9]{10}"
              value={swapInfo ? swapInfo.outputValue : 0}
              onChange={
              (e) => setSwapInfo(dataSwap => ({ ...dataSwap, outputValue: e.target.value }))
}
            />
            <SwitchCurrency>{swapInfo ? swapInfo.outputToken : outputToken}</SwitchCurrency>
          </div>
          <SwitchWrapper onClick={() => setSwapInfo(dataSwap => ({
            inputValue: dataSwap.outputValue,
            inputToken: dataSwap.outputToken,
            inputAddress: dataSwap.outputAddress,
            outputValue: dataSwap.inputValue,
            outputToken: dataSwap.inputToken,
            outputAddress: dataSwap.inputAddress,
          }))}
          >
            <SwitchButton>
              <img src={swapArrow} alt="Swap" className="switchButton" />
            </SwitchButton>
          </SwitchWrapper>
        </Inputs>
        {
          isMobile && swapInfo && (
            <SwapInfo>
              <div className="wrapperRows">
                <TotalRow>
                  <span className="nameTotal">Slippage</span>
                  <Total>0.5%</Total>
                </TotalRow>
                <TotalRow>
                  <span className="nameTotal">Network Free</span>
                  <Total>
                    ~
                    {estimate}
                  </Total>
                </TotalRow>
              </div>
              <SwapButton
                inputValue={swapInfo.inputValue}
                onClick={handleClick}
                disabled={!swapInfo.inputValue.length || (!approveFirst && !swapInfo.outputValue.length)}
              >
                {btnMessage}
              </SwapButton>
            </SwapInfo>
          )
        }
        {
          !isMobile && swapInfo && (
            <>
              <SwapInfo>
                <TotalRow>
                  <span className="nameTotal">Slippage</span>
                  <Total>0.5%</Total>
                </TotalRow>
                <TotalRow>
                  <span className="nameTotal">Network Free</span>
                  <Total>
                    ~
                    {estimate}
                  </Total>
                </TotalRow>
              </SwapInfo>
              <SwapButton
                inputValue={swapInfo.inputValue}
                onClick={handleClick}
                disabled={!swapInfo.inputValue.length || (!approveFirst && !swapInfo.outputValue.length)}
              >
                {btnMessage}
              </SwapButton>
            </>
          )
        }
        <ConfigCurrency openConfig={openConfig} isMobile={isMobile}>
          <ConfigInfo>
            <div className="configName">Slippage</div>
            <div className="configPercent">1.41%</div>
          </ConfigInfo>
          <div className="inputWrapper">
            <Switcher>
              <SwitchItem
                activeConfig={activeConfig === '1'}
                onClick={() => setActiveConfig('1')}
              >
                Auto
              </SwitchItem>
              <SwitchItem
                activeConfig={activeConfig === '2'}
                onClick={() => setActiveConfig('2')}
              >
                %
              </SwitchItem>
            </Switcher>
            <InputConfig
              placeholder="0"
              activeConfig={activeConfig}
              disabled={activeConfig === '1'}
            />
          </div>
          <div className="buttonWrapper">
            <Cancel onClick={() => setOpenConfig(false)}>Cancel</Cancel>
            <Confirm>Confirm</Confirm>
          </div>
        </ConfigCurrency>
      </SwapCurrencyWidget>
    </SwapContainer>
  );
};

export default SwapWidget;
