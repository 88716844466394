import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { scoreBoardItems } from '../../mocks/scoreBoardItems';
import { colors, device } from '../../variables/variables';
import { Container, Text10 } from '../../mixins/mixins';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Backend } from '../../lib/defi-sdk/backend';
import arrowLeft from '../../assets/img/slideLeft.svg';
import arrowRight from '../../assets/img/slideRight.svg';

const ScoreBoardComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 4rem;
  background: ${colors.bgDark};
  @media ${device.mobile} {
  
}
`;

const ScoreBoardContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(8, 8.8rem);
  justify-content: center;
  ${Container};
  @media ${device.mobile} {
    gap: 0;
    grid-template-columns: repeat(3, 8.8rem);
  }
`;

const BoardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${Text10};
  .top {
    display: flex;
    margin-bottom: 0.2rem;
  }
  @media ${device.mobile} {
    align-items: center;
  }
`;

const ItemName = styled.div`
  margin-right: 0.4rem;
  ${Text10};
`;

const ItemPrice = styled.div`
  font-weight: 400;
  ${Text10};
`;

const ItemPercent = styled.div`
  font-weight: 500;
  ${Text10};
  color: ${(props) => (props.percent ? 'green' : 'red')};
`;

const SlideLeft = styled.div`
  position: absolute;
  top: 1.4rem;
  left: 2.4rem;
`;

const SlideRight = styled.div`
  position: absolute;
  top: 1.4rem;
  right: 2.4rem;
`;

const SideLeft = styled.div`
  position: absolute;
  left: 2.4rem;
  top: 1.4rem;
`;

const SideRight = styled.div`
  position: absolute;
  right: 2.4rem;
  top: 1.4rem;
`;

const ScoreBoard = () => {
  const isMobile = useIsMobile();

  const [allocation, setAllocation] = useState([]);

  useEffect(() => {
    const main = async () => {
      const data = await new Backend().getAllocation();
      setAllocation(data);
    };
    main();
  }, []);

  return (
    <ScoreBoardComponent>
      {
        isMobile && (
          <SlideLeft>
            <img src={arrowLeft} alt="" className="arrowLeft" />
          </SlideLeft>
        )
      }
      <ScoreBoardContainer>
        {
          isMobile
          && (
            <>
              <SideLeft>
                <img src={arrowLeft} alt="Left" className="sideLeft" />
              </SideLeft>
              {scoreBoardItems.slice(0, 3).map((el, key) => {
                const item = allocation.length && allocation[key] ? allocation[key] : el;

                return (
                  <BoardItem key={key}>
                    <div className="top">
                      <ItemName>{item.name}</ItemName>
                      <ItemPrice>{`$${item.tokenPrice}`}</ItemPrice>
                    </div>
                    <ItemPercent percent={item.priceChange > 0}>{`${item.priceChange > 0 ? '+' : ''}${+item.priceChange}%`}</ItemPercent>
                  </BoardItem>
                );
              })}
              <SideRight>
                <img src={arrowRight} alt="Right" className="sideRight" />
              </SideRight>
            </>
          )
        }
        {
          !isMobile
          && (
            <>
              {allocation.length && allocation.map((item, key) => (
                <BoardItem key={key}>
                  <div className="top">
                    <ItemName>{item.name}</ItemName>
                    <ItemPrice>{`$${parseFloat(item.tokenPrice).toFixed(4)}`}</ItemPrice>
                  </div>
                  <ItemPercent percent={item.priceChange > 0}>{`${item.priceChange.toFixed(2) > 0 ? '+' : ''}${+item.priceChange.toFixed(2)}%`}</ItemPercent>
                </BoardItem>
              ))}
            </>
          )
        }
      </ScoreBoardContainer>
      {
        isMobile && (
          <SlideRight>
            <img src={arrowRight} alt="" className="arrowRight" />
          </SlideRight>
        )
      }
    </ScoreBoardComponent>
  );
};

export default ScoreBoard;
