import React from 'react';
import styled from 'styled-components';

const WalletAddressComponent = styled.abbr`
  color: #FFFF;
`;

const WalletAddress = function ({ address }) {
  const formattedAddress = (`${address.substr(0, 6)}...${address.substr(-4)}`).toLowerCase();
  return (
    <WalletAddressComponent>
      <span>{formattedAddress}</span>
    </WalletAddressComponent>
  );
};

export default WalletAddress;
