import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BodyTableMain, HeaderTable, MainTable, RowTable, TableCellMain,
} from '../../mixins/mixins';
import { constituentItems, constituentHeaderItems } from '../../mocks/constituentsData';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Backend } from '../../lib/defi-sdk/backend';
import { device } from '../../variables/variables';

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const justItem = constituentItems[0];

const ConstituentsComponent = styled.div` 
  @media ${device.mobile} {
    min-height: auto;
    padding: 0 2.4rem;
  }
`;

const Table = styled.div`
  ${MainTable};
`;

const TableHeader = styled.div`
  ${HeaderTable};
  grid-template-columns: 5.9rem 5.8rem 8.9rem 8.6rem 8.5rem 8.5rem 7rem 7.6rem;
  grid-gap: 0.2rem;
   @media(max-width:1024px){
   display:none;
   }
`;

const TableRow = styled.div`
  ${RowTable};
  grid-template-columns: 5.9rem 5.8rem 8.9rem 8.6rem 8.5rem 8.5rem 7rem 7.6rem;
  grid-gap: 0.2rem;
  @media (max-width:1024px){
  grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1px 0;
    .headerSymbol {
      display: flex;
    }
  }
`;

const HeaderCell = styled.div`
  ${TableCellMain};
  font-family: 'Inter', Arial, sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.4px;
  color: #616A72;
`;

const TableBody = styled.div`
  ${BodyTableMain};
  overflow-y: scroll;
  .loader {
    width: 100%;
    margin: 1.6rem;
    color: black;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.4px;
    justify-content: center;
    display: flex;
  }
`;

const BodyCell = styled.div`
  ${TableCellMain};
  position: relative;
  @media (max-width:1024px){
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

const BodyHeader = styled.div`
  color: #616a72;
  line-height: 1.6rem;
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
`;

const ConstituentsTitle = styled.div`
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
`;

const Icon = styled.img`
  align-self: flex-end;
  margin-right: 1.2rem;
    src: url(${props => props?.src});
  width: 2.4rem;
  height: 2.4rem;
`;

const BodyCellName = styled(BodyCell)`
  align-items: flex-end;
`;

const Constituents = () => {
  const isMobile = useIsMobile();
  const [allocation, setAllocation] = useState([]);

  useEffect(() => {
    const main = async () => {
      const data = await new Backend().getAllocation();
      setAllocation(data);
    };
    main();
  }, []);

  return (
    <ConstituentsComponent>
      {isMobile && <ConstituentsTitle>Constituents</ConstituentsTitle>}
      <Table>
        {!isMobile && (
          <TableHeader>
            {
                constituentHeaderItems.map(item => (
                  <HeaderCell key={item.id}>{item.title}</HeaderCell>
                ))
            }
          </TableHeader>
        )}
        <TableBody>
          {
            allocation.length <= 0 && (
              <div className="loader">Loading...</div>
            )
          }
          {
            allocation.length > 0 && (
              <>
                {
              (allocation.length ? allocation : constituentItems).map((item, i) => (
                <TableRow key={i}>
                  {
                    isMobile && (
                      <div className="headerSymbol">
                        <BodyCell><Icon src={item.icon} /></BodyCell>
                        <BodyCell>{item.symbol}</BodyCell>
                      </div>
                    )
                  }
                  {
                    !isMobile && (
                      <>
                        <BodyCell><Icon src={item.icon} /></BodyCell>
                        <BodyCell>{item.symbol}</BodyCell>
                      </>
                    )
                  }
                  {isMobile && (
                    <>
                      <BodyCell />
                      <BodyCellName>{item.genre}</BodyCellName>
                    </>
                  )}
                  {
                    !isMobile && (
                      <BodyCell>
                        {isMobile && <BodyHeader>Genre</BodyHeader> }
                        {justItem.genre}
                      </BodyCell>
                    )
                  }
                  <BodyCell>
                    {isMobile && <BodyHeader>Network</BodyHeader> }
                    {item.network}
                  </BodyCell>
                  <BodyCell>
                    {isMobile && <BodyHeader>Market Cap</BodyHeader> }
                    {item.marketCap > 1_000_000 ? `${(item.marketCap / 1_000_000).toFixed(2)}M` : `${numberWithCommas(item.marketCap)}$` }
                    {/* {numberWithCommas(item.marketCap)} */}
                    $
                  </BodyCell>
                  <BodyCell>
                    {isMobile && <BodyHeader>Quantity</BodyHeader> }
                    {parseFloat(item.tokenPrice).toFixed(4)}
                    $
                  </BodyCell>
                  <BodyCell>
                    {isMobile && <BodyHeader>Price</BodyHeader> }
                    {item.allocationPercent}
                  </BodyCell>
                  <BodyCell>
                    {isMobile && <BodyHeader>Allocation</BodyHeader> }
                    {item.amount}
                  </BodyCell>
                </TableRow>
              ))
          }
              </>
            )
          }
        </TableBody>
      </Table>
    </ConstituentsComponent>

  );
};

export default Constituents;
