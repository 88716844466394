import React, { useState } from 'react';
import styled from 'styled-components';
import Tabs from '../Tabs';
import OverView from '../../features/overView';
import Data from '../../features/Data';
import Constituents from '../../features/Constituents';
import { useIsMobile } from '../../hooks/useIsMobile';

const RouterContainer = styled.div`
  background: white;
  border-radius: 4px;
  margin-bottom: 9.3rem;
  @media (max-width: 1024px) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const SwapMobileBtnWrapper = styled.div`
  position: sticky;
  padding: 2.4rem;
  width: 100%;
  background: #fff;
  bottom: 0;
  z-index: 10;
`;

const SwapMobileBtn = styled.button`
  background: #095AAB;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 16px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

const RouterContainerComponent = ({ setOpenPopup }) => {
  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState('Overview');
  return (
    <RouterContainer>
      {!isMobile && <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />}
      {
                (activeTab === 'Overview' || isMobile)
                && (
                <OverView />
                )
            }
      {
                (activeTab === 'Data' || isMobile)
                && (
                <Data />
                )
            }
      {
                (activeTab === 'Constituents' || isMobile)
                && (
                <Constituents />
                )
            }
      {
        isMobile
        && (
        <SwapMobileBtnWrapper>

          <SwapMobileBtn onClick={() => setOpenPopup(true)}>
            Swap
          </SwapMobileBtn>

        </SwapMobileBtnWrapper>
        )
        }
    </RouterContainer>
  );
};

export default RouterContainerComponent;
