import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { SwapWidget } from './swapWidget';
import { CurrencyModal } from './swapWidget/CurrencyModal';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background: rgba(160,160,160, 1);
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 100%;
  min-height: 100vh;
`;

const Swapper = styled.div`
  display: flex;
`;

export const AdminComponent = () => {
  const [selected, setSelected] = useState(false);
  const [allocation, setAllocation] = useState(false);
  const [inputSwapInfo, setInputSwapInfo] = useState(false);
  const ref = useRef(null);

  const onSelect = ({ modal, allocationData }) => {
    console.log({ modal, allocationData });
    setSelected(modal);
    setAllocation(allocationData || []);
  };

  const onInputSwapSelect = ({ symbol, address }) => {
    setInputSwapInfo({ inputToken: symbol, inputAddress: address });
    ref.current.changeSwapInfo({ inputToken: symbol, inputAddress: address });
  };

  return (
    <Main>
      <Wrapper>
        <Swapper>
          <SwapWidget ref={ref} inputSwapInfo={inputSwapInfo} onSelect={onSelect} />
          {selected
            ? (
              <CurrencyModal
                onInputSwapSelect={onInputSwapSelect}
                allocation={allocation}
                onSelect={onSelect}
              />
            )
            : null}
        </Swapper>
      </Wrapper>
    </Main>
  );
};
