import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Text10,
  Text12,
  Text14,
  Text16,
  Text24s48Normal,
  Text32Normal,
} from '../../mixins/mixins';
import { colors, device } from '../../variables/variables';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Backend } from '../../lib/defi-sdk/backend';

const CurrencyWidgetComponent = styled.div`
  width: 100%;
  background: white;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  color: black;
  background: ${colors.bgLightWhite};
  @media ${device.mobile} {
    padding-top: 0;
    background: white;
    padding-bottom: 4.8rem;
  }
`;

const CurrencyWidgetContainer = styled.div`
  background: white;
  padding: 1.4rem 2.4rem 2rem;
  border-radius: 4px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Bottom = styled.div`
  width: 100%;
  margin-top: 2.1rem;
  @media ${device.mobile} {
    margin-top: 0.6rem;
  }
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media ${device.mobile} {
    grid-template-columns: repeat(3, 10rem);
    gap: 0.8rem;
    row-gap: 1.8rem;
  }
`;

const BottomItem = styled.div`
  display: flex;
  flex-direction: column;
  text-rendering: optimizeLegibility;

  .itemWrapper {
    display: flex;
    align-items: center;
  }

  @media ${device.mobile} {
  }
`;

const Duration = styled.div`
  margin-bottom: 0.7rem;
  ${Text12};
  color: rgba(19, 32, 45, 0.65);
`;

const CurrentValue = styled.div`
  white-space: nowrap;
  margin-right: 0.5rem;
  ${Text14};
`;

const CurrentPercentGreen = styled.div`
  color: rgba(40, 181, 80, 1);
  ${Text12};
`;

const CurrentPercentRed = styled.div`
  color: rgba(222, 27, 27, 1);
  ${Text12}
`;

const Left = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 1.4rem;

  &:before {
    content: '';
    width: 7.2rem;
    height: 0.1rem;
    position: absolute;
    bottom: 0;
    background: rgba(53, 72, 91, 0.2);
    @media ${device.mobile} {
      width: 0;
    }
  }
`;

const CurrencyName = styled.h2`
  ${Text32Normal};
  display: flex;
  align-items: center;
  @media ${device.mobile} {
  }
`;

const CurrencyPrice = styled.div`
  ${Text24s48Normal};
  color: rgba(40, 181, 80, 1);
`;

const Nint = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  background: rgba(97, 106, 114, 0.2);
  ${Text14};
  color: #095aab;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  cursor: default;
  margin-left: 1.2rem;
  :hover {
    background: linear-gradient(90deg, #095aab 0%, #024c96 100%);
    color: white;
  }
`;

const Right = styled.div`
  padding-top: 2.4rem;
  @media ${device.mobile} {
    padding-top: 1rem;
  }
`;

const SymbolCode = styled.div`
  display: flex;
  padding: 0.5rem 1.2rem 0.3rem;
  justify-content: center;
  align-items: center;
  background: white;
  border: 2px solid #a9b9cb;
  border-radius: 4px;

  .symbol {
    ${Text14};
    margin-right: 0.3rem;
  }

  .symbolCode {
    ${Text16};
    line-height: 2rem;
    color: #095aab;
  }

  @media ${device.mobile} {
    width: 12.4rem;
    height: 2.4rem;
    background: white;
  }
`;

const NintInfo = styled.div`
  position: absolute;
  padding-left: 0.8rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  top: 5.3rem;
  left: 16.8rem;
  width: 18.4rem;
  background: #1a1a1a;
  border-radius: 0.4rem;
  color: white;
  ${Text10};
  font-weight: 500;
  display: ${(props) => (props.visibleNintInfo ? 'block' : 'none')};
`;

const CurrencyWidget = () => {
  const [visibleNintInfo, setVisibleNintInfo] = useState(false);
  const [indexInfo, setIndexInfo] = useState({});
  const isMobile = useIsMobile();

  useEffect(() => {
    const main = async () => {
      setIndexInfo(await new Backend().getGeneral());
    };
    main();
  }, []);

  return (
    <CurrencyWidgetComponent>
      <CurrencyWidgetContainer>
        <Top>
          {isMobile && (
            <Right>
              <SymbolCode>
                <p className="symbol">Symbol:</p>
                <span className="symbolCode">IGB10</span>
              </SymbolCode>
            </Right>
          )}
          <Left>
            <CurrencyName>
              GameFI BSC-20
              <Nint
                onMouseEnter={() => setVisibleNintInfo(true)}
                onMouseLeave={() => setVisibleNintInfo(false)}
              >
                ?
              </Nint>
            </CurrencyName>
            <CurrencyPrice>
              {`${indexInfo.price ? indexInfo.price : 100} BUSD`}
            </CurrencyPrice>
            <NintInfo visibleNintInfo={visibleNintInfo}>
              Top 10 play to earn games on Binance Smart Chain network
            </NintInfo>
          </Left>
          {!isMobile && (
            <Right>
              <SymbolCode>
                <p className="symbol">Symbol:</p>
                <span className="symbolCode">IGB10</span>
              </SymbolCode>
            </Right>
          )}
        </Top>
        <Bottom>
          <BottomContainer>
            <BottomItem>
              <Duration>24h Change</Duration>
              <div className="itemWrapper">
                <CurrentValue>{indexInfo.price}</CurrentValue>
                <div>
                  {indexInfo.priceChange > 0 ? (
                    <CurrentPercentGreen>
                      {`${
                        indexInfo.priceChange > 0 ? '+' : ''
                      }${+indexInfo.priceChange}%`}
                    </CurrentPercentGreen>
                  ) : (
                    <CurrentPercentRed>
                      {`${
                        indexInfo.priceChange > 0 ? '+' : ''
                      }${+indexInfo.priceChange}%`}
                    </CurrentPercentRed>
                  )}
                </div>
              </div>
            </BottomItem>
            <BottomItem>
              <Duration>24h High</Duration>
              <div className="itemWrapper">
                <CurrentValue>
                  {indexInfo.priceHigh ? indexInfo.priceHigh.toFixed(2) : 0}
                </CurrentValue>
                <div>
                  {indexInfo.priceChangeHigh > 0 ? (
                    <CurrentPercentGreen>
                      {`${
                        indexInfo.priceChangeHigh
                          ? indexInfo.priceChangeHigh.toFixed(2)
                          : 0
                      }%`}
                    </CurrentPercentGreen>
                  ) : (
                    <CurrentPercentRed>
                      {`${
                        indexInfo.priceChangeHigh
                          ? indexInfo.priceChangeHigh.toFixed(2)
                          : 0
                      }%`}
                    </CurrentPercentRed>
                  )}
                </div>
              </div>
            </BottomItem>
            <BottomItem>
              <Duration>24h Low</Duration>
              <div className="itemWrapper">
                <CurrentValue>
                  {indexInfo.priceLow ? indexInfo.priceLow.toFixed(2) : 0}
                </CurrentValue>
                <div>
                  {indexInfo.priceChangeLow > 0 ? (
                    <CurrentPercentGreen>
                      {`${
                        indexInfo.priceChangeLow
                          ? indexInfo.priceChangeLow.toFixed(2)
                          : 0
                      }%`}
                    </CurrentPercentGreen>
                  ) : (
                    <CurrentPercentRed>
                      {`${
                        indexInfo.priceChangeLow
                          ? indexInfo.priceChangeLow.toFixed(2)
                          : 0
                      }%`}
                    </CurrentPercentRed>
                  )}
                </div>
              </div>
            </BottomItem>
            <BottomItem>
              <Duration>Market Cap</Duration>
              <div className="itemWrapper">
                <CurrentValue>
                  {indexInfo.marketCap ? `$${indexInfo.marketCap}` : '$131.17M'}
                </CurrentValue>
              </div>
            </BottomItem>
            {/* <BottomItem>
              <Duration>Net Asset Value</Duration>
              <div className="itemWrapper">
                <CurrentValue>$0.00</CurrentValue>
              </div>
            </BottomItem> */}
          </BottomContainer>
        </Bottom>
      </CurrencyWidgetContainer>
    </CurrencyWidgetComponent>
  );
};

export default CurrencyWidget;
