export const constituentHeaderItems = [
  {
    id: 1,
    title: '',
  },
  {
    id: 2,
    title: 'Symbol',
  },
  {
    id: 3,
    title: 'Genre',
  },
  {
    id: 4,
    title: 'Network',
  },
  {
    id: 5,
    title: 'Market Cap',
  }, {
    id: 6,
    title: 'Price',
  },
  {
    id: 7,
    title: 'Allocation',
  },
  {
    id: 8,
    title: 'Quantity',
  },
];
export const constituentItems = [
  {
    isLoading: 'Loading...',
    genre: 'RPG',
    network: 'LAO',
  },
];
