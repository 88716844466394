import React from 'react';
import styled from 'styled-components';
import { Text14 } from '../../../../mixins/mixins';

const TabsNavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.4rem;
  height: 3.2rem;
  ${Text14};
  cursor: pointer;
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 10.4rem;
    height: 2px;
    background: #095AAB;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: 0.2s opacity ease-in-out;
  }
`;

const TabsNav = ({ activeTab, nameTab, onClick }) => (
  <TabsNavItem active={activeTab === nameTab} onClick={() => onClick(nameTab)}>
    {nameTab}
  </TabsNavItem>
);

export default TabsNav;
