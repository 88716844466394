export const tokenSetABI = [{ inputs: [{ internalType: 'address[]', name: '_components', type: 'address[]' }, { internalType: 'int256[]', name: '_units', type: 'int256[]' }, { internalType: 'address[]', name: '_modules', type: 'address[]' }, { internalType: 'contract IController', name: '_controller', type: 'address' }, { internalType: 'address', name: '_manager', type: 'address' }, { internalType: 'string', name: '_name', type: 'string' }, { internalType: 'string', name: '_symbol', type: 'string' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'spender', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }],
  name: 'Approval',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }],
  name: 'ComponentAdded',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }],
  name: 'ComponentRemoved',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }, {
    indexed: false, internalType: 'int256', name: '_realUnit', type: 'int256',
  }],
  name: 'DefaultPositionUnitEdited',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: '_positionModule', type: 'address',
  }, {
    indexed: false, internalType: 'bytes', name: '_data', type: 'bytes',
  }],
  name: 'ExternalPositionDataEdited',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: '_positionModule', type: 'address',
  }, {
    indexed: false, internalType: 'int256', name: '_realUnit', type: 'int256',
  }],
  name: 'ExternalPositionUnitEdited',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_target', type: 'address',
  }, {
    indexed: true, internalType: 'uint256', name: '_value', type: 'uint256',
  }, {
    indexed: false, internalType: 'bytes', name: '_data', type: 'bytes',
  }, {
    indexed: false, internalType: 'bytes', name: '_returnValue', type: 'bytes',
  }],
  name: 'Invoked',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: '_newManager', type: 'address',
  }, {
    indexed: false, internalType: 'address', name: '_oldManager', type: 'address',
  }],
  name: 'ManagerEdited',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_module', type: 'address',
  }],
  name: 'ModuleAdded',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_module', type: 'address',
  }],
  name: 'ModuleInitialized',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_module', type: 'address',
  }],
  name: 'ModuleRemoved',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_module', type: 'address',
  }],
  name: 'PendingModuleRemoved',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: '_positionModule', type: 'address',
  }],
  name: 'PositionModuleAdded',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: '_component', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: '_positionModule', type: 'address',
  }],
  name: 'PositionModuleRemoved',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'int256', name: '_newMultiplier', type: 'int256',
  }],
  name: 'PositionMultiplierEdited',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }],
  name: 'Transfer',
  type: 'event',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }], name: 'addComponent', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_positionModule', type: 'address' }], name: 'addExternalPositionModule', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_module', type: 'address' }], name: 'addModule', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'spender', type: 'address' }], name: 'allowance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'approve', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'uint256', name: '_quantity', type: 'uint256' }], name: 'burn', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'components', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'controller', outputs: [{ internalType: 'contract IController', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }], name: 'decreaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'int256', name: '_realUnit', type: 'int256' }], name: 'editDefaultPositionUnit', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_positionModule', type: 'address' }, { internalType: 'bytes', name: '_data', type: 'bytes' }], name: 'editExternalPositionData', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_positionModule', type: 'address' }, { internalType: 'int256', name: '_realUnit', type: 'int256' }], name: 'editExternalPositionUnit', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'int256', name: '_newMultiplier', type: 'int256' }], name: 'editPositionMultiplier', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'getComponents', outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }], name: 'getDefaultPositionRealUnit', outputs: [{ internalType: 'int256', name: '', type: 'int256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_positionModule', type: 'address' }], name: 'getExternalPositionData', outputs: [{ internalType: 'bytes', name: '', type: 'bytes' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }], name: 'getExternalPositionModules', outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_positionModule', type: 'address' }], name: 'getExternalPositionRealUnit', outputs: [{ internalType: 'int256', name: '', type: 'int256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'getModules', outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [],
  name: 'getPositions',
  outputs: [{
    components: [{ internalType: 'address', name: 'component', type: 'address' }, { internalType: 'address', name: 'module', type: 'address' }, { internalType: 'int256', name: 'unit', type: 'int256' }, { internalType: 'uint8', name: 'positionState', type: 'uint8' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], internalType: 'struct ISetToken.Position[]', name: '', type: 'tuple[]',
  }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }], name: 'getTotalComponentRealUnits', outputs: [{ internalType: 'int256', name: '', type: 'int256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'addedValue', type: 'uint256' }], name: 'increaseAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'initializeModule', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_target', type: 'address' }, { internalType: 'uint256', name: '_value', type: 'uint256' }, { internalType: 'bytes', name: '_data', type: 'bytes' }], name: 'invoke', outputs: [{ internalType: 'bytes', name: '_returnValue', type: 'bytes' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }], name: 'isComponent', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_module', type: 'address' }], name: 'isExternalPositionModule', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_module', type: 'address' }], name: 'isInitializedModule', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'isLocked', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_module', type: 'address' }], name: 'isPendingModule', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'lock', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'locker', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'manager', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_account', type: 'address' }, { internalType: 'uint256', name: '_quantity', type: 'uint256' }], name: 'mint', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'moduleStates', outputs: [{ internalType: 'enum ISetToken.ModuleState', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'modules', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'positionMultiplier', outputs: [{ internalType: 'int256', name: '', type: 'int256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }], name: 'removeComponent', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_component', type: 'address' }, { internalType: 'address', name: '_positionModule', type: 'address' }], name: 'removeExternalPositionModule', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_module', type: 'address' }], name: 'removeModule', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_module', type: 'address' }], name: 'removePendingModule', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_manager', type: 'address' }], name: 'setManager', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'recipient', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transfer', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'sender', type: 'address' }, { internalType: 'address', name: 'recipient', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'transferFrom', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'unlock', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, { stateMutability: 'payable', type: 'receive' }];
