/* eslint-disable */
import { erc20ABI } from './utils/erc20ABI'
import { exchangeIssuanceABI } from './utils/exchangeIssuanceABI'
import { tokenSetABI } from './utils/tokenSetABI'
import { tradeABI } from './utils/tradeABI'

export class DefiSDK {
  constructor({ web3, config }) {
    this.web3 = web3
    this.config = config

    this.exchangeIssuanceAddress = config.internal.ExchangeIssuanceV2
    this.indexAddress = config.internal.TokenSet
    this.tradeAddress = config.internal.TradeModule

    this.allowanceNumber = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
  }

  // swap widget

  async getEthSwap({ swapInfo, owner }) {
    const exchangeIssuanceContract = new this.web3.eth.Contract(exchangeIssuanceABI, this.exchangeIssuanceAddress)

    const anountToIssue = await exchangeIssuanceContract.methods.getEstimatedIssueSetAmount(
      this.indexAddress,
      swapInfo.inputAddress,
      this.web3.utils.toWei(swapInfo.inputValue, 'ether')
    ).call()

    const res = this.web3.utils.fromWei(anountToIssue, 'ether')
    
    let estimation
    try {
      const data = await this.buyIndex({ swapInfo, owner, estimation: true })
      estimation = this.getEstimationUnits(data)
    } catch (err) {
      estimation = 'error'
    }

    return { value: Number(res).toFixed(6), estimation }
  }

  async getTokenSwap ({ swapInfo, owner }) {
    const exchangeIssuanceContract = new this.web3.eth.Contract(exchangeIssuanceABI, this.exchangeIssuanceAddress)

    const anountToRedeem = await exchangeIssuanceContract.methods.getAmountOutOnRedeemSet(
      swapInfo.inputAddress,
      swapInfo.outputAddress,
      this.web3.utils.toWei(swapInfo.inputValue, 'ether')
    ).call()

    const res = this.web3.utils.fromWei(anountToRedeem, 'ether')
    
    let estimation
    try {
      const data = await this.sellIndex({ swapInfo, owner, estimation: true })
      estimation = this.getEstimationUnits(data)
    } catch (err) {
      estimation = 'error'
    }

    return { value: Number(res).toFixed(6), estimation }
  }

  // swap

  async buyIndex ({ swapInfo, owner, estimation = false }) {
    const gasPrice = await this.web3.eth.getGasPrice()
    const exchangeIssuanceContract = new this.web3.eth.Contract(exchangeIssuanceABI, this.exchangeIssuanceAddress)

    const amountInputTokens = await exchangeIssuanceContract.methods.getAmountInToIssueExactSet(
      this.indexAddress,
      swapInfo.inputAddress,
      this.web3.utils.toWei(swapInfo.outputValue, 'ether'),
    ).call()

    const { toWei, toBN } = this.web3.utils
    const maxIn = toBN(amountInputTokens).mul(toBN(toWei('1.05'))).div(toBN(toWei('1'))).toString()

    const tx = exchangeIssuanceContract.methods.issue(
      this.indexAddress,
      swapInfo.inputAddress,
      this.web3.utils.toWei(swapInfo.outputValue, 'ether'),
      maxIn
    )
    
    if (estimation) {
      return await tx.estimateGas({ from: owner, gasPrice })
    } else {
      return await tx.send({ from: owner, gasPrice })
    }
  }

  async sellIndex ({ swapInfo, owner, estimation = false }) {
    const gasPrice = await this.web3.eth.getGasPrice()
    const exchangeIssuanceContract = new this.web3.eth.Contract(exchangeIssuanceABI, this.exchangeIssuanceAddress)

    const amountOutTokens = await exchangeIssuanceContract.methods.getAmountOutOnRedeemSet(
      this.indexAddress,
      swapInfo.outputAddress,
      this.web3.utils.toWei(swapInfo.inputValue, 'ether'),
    ).call()

    const { toWei, toBN } = this.web3.utils
    const minOut = toBN(amountOutTokens).mul(toBN(toWei('1'))).div(toBN(toWei('1.05'))).toString()

    const tx = exchangeIssuanceContract.methods.redeem(
      this.indexAddress,
      swapInfo.outputAddress,
      this.web3.utils.toWei(swapInfo.inputValue, 'ether'),
      minOut
    )

    if (estimation) {
      return await tx.estimateGas({ from: owner, gasPrice })
    } else {
      return await tx.send({ from: owner, gasPrice })
    }
  }

  // allowance

  async checkApproved({ address, owner }) {
    const sender = owner === 'index' ? this.indexAddress : owner
    const ethContract = new this.web3.eth.Contract(erc20ABI, address)
    const allowance = await ethContract.methods.allowance(sender, this.exchangeIssuanceAddress).call()
    return allowance.length > 25 ? true : false 
  }

  async approve({ address, owner }) {
    const gasPrice = await this.web3.eth.getGasPrice()
    const tokenContract = new this.web3.eth.Contract(erc20ABI, address)
    await tokenContract.methods.approve(this.exchangeIssuanceAddress, this.allowanceNumber).send({ from: owner, gasPrice })
  }

  // market info

  async getUserTokenBalance({ tokenAddress, owner }) {
    const address = owner === 'index' ? this.indexAddress : owner;
    const erc20contract = new this.web3.eth.Contract(erc20ABI, tokenAddress)
    const _balance = await erc20contract.methods.balanceOf(address).call()
    const decimal = await erc20contract.methods.decimals().call()
    const balance = (+_balance / 10 ** +decimal).toFixed(6)
    return balance
  }

  getEstimationUnits(num) {
    const BNBUSD = 375
    const eth = this.web3.utils.fromWei(num.toString(), 'gwei')
    const data = (+eth * BNBUSD).toFixed(2)
    return `$${data}`
  }

  // admin
  async getTokenInfo(address) {
    const erc20contract = new this.web3.eth.Contract(erc20ABI, address)
    const balance = await erc20contract.methods.balanceOf(this.indexAddress).call()
    const decimal = await erc20contract.methods.decimals().call()
    const name = await erc20contract.methods.name().call()
    const symbol = await erc20contract.methods.symbol().call()

    const addressView = `${address.slice(0, 6)}...${address.slice(address.length - 4)}`

    const obj = {
      address,
      addressView,
      name,
      symbol,
      amount: balance == 0 ? 0 : Number((balance / 10 ** decimal).toFixed(3))
    }

    return obj
  }

  async getAllocation() {
    this.tokenSet = new this.web3.eth.Contract(tokenSetABI, this.indexAddress)
    const positions = await this.tokenSet.methods.getComponents().call()
    if (positions.length) {
      return Promise.all(positions.map(address => this.getTokenInfo(address)))
    } else {
      return []
    }
  }

  async tradeAllocation({ swapInfo, owner, estimation }) {
    const gasPrice = await this.web3.eth.getGasPrice()
    const tradeContract = new this.web3.eth.Contract(tradeABI, this.tradeAddress)

    const tx = tradeContract.methods.trade(
      this.indexAddress,
      'ZeroExApiAdapterV5',
      swapInfo.inputAddress,
      this.web3.utils.toWei(swapInfo.inputValue, 'ether'),
      swapInfo.outputAddress,
      '0',
      '0x415565b0'
    )

    if (estimation) {
      return await tx.estimateGas({ from: owner, gasPrice })
    } else {
      return await tx.send({ from: owner, gasPrice })
    }

  }
}
