export const colors = {
  bgDark: '#1A1A1A',
  white: '#FFFFFF',
  bgLightWhite: 'rgba(242, 244, 244, 0.95)',
  bgLightGrey: '#F0F2F2',
  bgLightBlue: 'rgba(9, 90, 171, 0.2)',
  bgBlue: 'rgba(9, 90, 171, 1)',
  bgLightBlue2: '#DAE6F2',
};

export const breakpoints = {
  mobile: '1023px',
};

export const device = {
  mobile: `(max-width: ${breakpoints.mobile})`,
  noTouch: '(hover), (min-width:0\0), (min--moz-device-pixel-ratio:0)',
};

export const initialSwapInfo = {
  inputToken: 'WBNB',
  inputValue: '',
  inputAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  outputToken: 'IGB10',
  outputValue: '',
  outputAddress: '0x1be476ed8541c550649126252fc0cee71c53b88f',
};
