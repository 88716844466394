import React, { useContext } from 'react';
import styled from 'styled-components';
import { WalletContext } from '../../lib/wallet';
import WalletAddress from '../WalletAddress';
import { colors, device } from '../../variables/variables';
import { useIsMobile } from '../../hooks/useIsMobile';
import walletIco from '../../assets/img/balance_icon.svg';

const ConnectWalletComponent = styled.button`
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
  width: 16rem;
  height: 4rem;
  background: ${colors.bgBlue};
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #FFFFFF;
  @media ${device.mobile} {
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    margin-right: 0;
    background: ${colors.bgBlue};
  }
`;

const ConnectWallet = () => {
  const {
    connect, isConnected, accounts, isMetamask, isLoadingMetamask,
  } = useContext(WalletContext);

  const copyAddress = async () => {
    await navigator.clipboard.writeText(accounts[0]);
  };

  const installMetamask = () => {
    window.open('https://metamask.io/download/', '_blank');
  };

  const isMobile = useIsMobile();

  if (isLoadingMetamask) {
    return null;
  }

  return (
    <>
      {
        !isMobile
        && (
          <>
            {
              isMetamask ? (
                <ConnectWalletComponent onClick={isConnected ? copyAddress : connect}>
                  {isConnected ? <WalletAddress address={accounts[0]} /> : 'Connect Wallet'}
                </ConnectWalletComponent>
              )
                : (
                  <ConnectWalletComponent onClick={installMetamask}>
                    Install metamask
                  </ConnectWalletComponent>
                )
            }
          </>
        )
      }
      {
        isMobile
        && (
          <>
            {
              isMetamask ? (
                <ConnectWalletComponent onClick={isConnected ? copyAddress : connect}>
                  {isConnected ? <img src={walletIco} alt="wallet" /> : <img src={walletIco} alt="wallet" />}
                </ConnectWalletComponent>
              )
                : (
                  <ConnectWalletComponent onClick={installMetamask}>
                    <img src={walletIco} alt="wallet" />
                  </ConnectWalletComponent>
                )
            }
          </>
        )
      }
    </>
  );
};

export default ConnectWallet;
