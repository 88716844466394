import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ScoreBoard from '../../features/ScoreBoard';
import Header from '../../features/Header';
import CurrencyWidget from '../../features/CurrencyWidget';
import RouterContainer from '../../components/RouterContainer';
import Footer from '../../features/Footer';
import SwapWidget from '../../components/swapWidget';
import { useIsMobile } from '../../hooks/useIsMobile';
import FormContactUS from '../../components/FormContactUs';
import { WalletContext } from '../../lib/wallet';
import { device } from '../../variables/variables';
import { Container } from '../../mixins/mixins';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background: rgba(240, 242, 242, 1);
  @media ${device.mobile} {
    background: white;
    height: auto;
  }
`;

const MainColumns = styled.div`
  ${Container};
  display: flex;
  @media ${device.mobile} {
    background: white;
    padding: 0;
  }
`;

const MainLeft = styled.div`
  width: 64.8rem;
  margin-right: 2.4rem;
  @media ${device.mobile} {
    width: 100%;
    margin-right: 0;
  }
`;

const MainRight = styled.div`
  width: 42.4rem;
`;

const PopupSwap = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 11;
  opacity: ${(props) => (props.openPopup ? 1 : 0)};
  transition: 0.3s opacity ease-in-out;
  visibility: ${(props) => (props.openPopup ? 'visible' : 'hidden')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PopupOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(53, 72, 91, 0.9);
  transition: 0.5s opacity ease-in-out;
`;

const PopupContact = styled(PopupSwap)`
  opacity: ${(props) => (props.openPopupContact ? 1 : 0)};
  transition: 0.3s opacity ease-in-out;
  visibility: ${(props) => (props.openPopupContact ? 'visible' : 'hidden')};
  display: ${(props) => (props.openPopupContact ? 'block' : 'none')};
`;

const StepLine = styled.div`
  width: 100%;
  height: 0.1rem;
  background: rgba(221, 228, 237, 1);
`;

const MainComponent = () => {
  const isMobile = useIsMobile();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupContact, setOpenPopupContact] = useState(false);
  const wallet = useContext(WalletContext);

  return (
    <Main>
      <ScoreBoard />
      <Header
        openPopupContact={openPopupContact}
        setOpenPopupContact={setOpenPopupContact}
      />
      <StepLine />
      <MainColumns>
        <MainLeft>
          <CurrencyWidget />
          <RouterContainer
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          />
        </MainLeft>
        {
          !isMobile
          && (
            <MainRight>
              <SwapWidget />
            </MainRight>
          )
        }
      </MainColumns>
      <PopupContact openPopupContact={openPopupContact}>
        <PopupOverlay
          onClick={(e) => {
            setOpenPopup(false);
            e.stopPropagation();
          }}
        />
        <FormContactUS
          setOpenPopupContact={setOpenPopupContact}
          openPopupContact={openPopupContact}
        />
      </PopupContact>
      {
        isMobile
        && (
          <PopupSwap
            openPopup={openPopup}
          >
            <PopupOverlay onClick={(e) => {
              setOpenPopup(false);
              e.stopPropagation();
            }}
            />
            <SwapWidget wallet={wallet} />
          </PopupSwap>
        )
      }
      <Footer />
    </Main>
  );
};

export default MainComponent;
